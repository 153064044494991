import { defineStore } from 'pinia'
import { format } from 'date-fns'
import get from 'lodash/get'

import { dependencySatisfied } from '~/libs/question-helpers'
import { lsUtils } from '~/libs/ls-utils-service'
import QUESTION_WIDGETS from '~/libs/question-widgets'
import QuestionSorter from '~/modules/Marketplace/QuestionSorter'

import { useBorrowerStore } from '~/store/borrower'
import { useMatchingStore } from '~/store/matching'
import { useQuestionsStore } from '~/store/questions'
import { useUserStore } from '~/store/user'

// Head and Tail of Basic Info section hard coded for now since the position in basic info is not configured in the question filters
const BASIC_INFO_HEAD = [
  'financeAmount',
  'creditScore',
  'average_monthly_sales',
  'business_start_date',
  'lendioIndustry',
  'bankruptcy',
  'bankruptcy_status',
  'bankruptcy_discharged_date',
]

const BASIC_INFO_TAIL = ['fundAspect']

export const useMarketplaceStore = defineStore('marketplace', () => {
  const questionsStore = useQuestionsStore()
  const matchingStore = useMatchingStore()

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const loadingBasicInfo = ref(0)
  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/
  /**
   * This returns the whitelist of questions allowed in the app. This is managed in Nova under
   * Question Filters. If they are present with type "marketplace" and do not need authentication,
   * they will be included in the whitelist.
   *
   * An exception to this whitelist is if a widget depends on a field in the whitelist, all of its other
   * fields might appear as well in that widget, no matter whether they're in the whitelist or not.
   * @returns
   */
  const basicInfoWhitelist = computed(() => {
    const aliasFilters = questionsStore.appQuestionFilters['marketplace'] || []
    return aliasFilters.reduce((carry, { fieldAlias, afterAuth }) => {
      if (!afterAuth) {
        carry[fieldAlias] = true
      }
      return carry
    }, {})
  })

  /**
   * Some basic info questions are always asked. This is configurable in Nova under Question Filters.
   * If they don't need authentication and are marked always asked, they will always be asked (in some form)
   * in the basic-info section
   * @returns aliases
   */
  const basicInfoAlwaysAsked = computed(() => {
    const aliasFilters = questionsStore.appQuestionFilters['marketplace'] || []
    return aliasFilters
      .filter(({ afterAuth, alwaysAsked }) => !afterAuth && alwaysAsked)
      .map(({ fieldAlias }) => fieldAlias)
  })

  /**
   * Returns the QuestionSorter object that sorts, filters, and overrides an alias array for Basic Info
   * @returns
   */
  const basicInfoSorter = computed(() => {
    // Get widgets from predefined lib
    const widgets = QUESTION_WIDGETS.filter((question) => question.fields)

    // TODO: update the questionSorter.
    // Instantiate sorter
    const sorter = new QuestionSorter({
      widgets,
      head: BASIC_INFO_HEAD,
      aliases: matchingStore.qualifiersForAllProducts
        .concat(basicInfoAlwaysAsked.value)
        .filter((key) => basicInfoWhitelist.value.hasOwnProperty(key)),
      tail: BASIC_INFO_TAIL,
      // rootGetters
    })
    // Initialize sorter cache with above config
    sorter.getQuestionsWithoutWidgetChildren()
    return sorter
  })

  /**
   * Takes the present match fields, already answered fields, and always asked fields (from Question Filters in Nova),
   * sorts them, and returns that list after sorting it according to the QuestionSorter configuration.
   * @returns
   */
  const basicInfoQuestions = computed(() => {
    const savedAnswers = questionsStore.savedAnswers || {}

    const matchingQuestions =
      matchingStore.qualifiersForPotentialProducts.filter(
        (alias) => basicInfoWhitelist.value[alias]
      )

    const sortedList = basicInfoSorter.value.getSortedQuestionIntersect(
      matchingQuestions
        .concat(basicInfoAlwaysAsked.value)
        .concat(Object.keys(savedAnswers))
    )

    return sortedList
  })

  const filteredBasicInfoQuestions = computed(() => {
    return basicInfoQuestions.value.filter((alias) => {
      return dependencySatisfied(
        questionsStore.componentQuestions[alias],
        questionsStore.componentQuestions
      )
    })
  })

  const nextBasicInfoQuestion = computed(() => {
    return ({ alias = null, forward = true } = {}) => {
      if (alias) {
        let index = basicInfoQuestions.value.indexOf(alias)
        if (index == -1) {
          return nextBasicInfoQuestion()
        }
        index += forward ? 1 : -1
        let nextAlias
        while (index < basicInfoQuestions.value.length && index >= 0) {
          nextAlias = basicInfoQuestions.value[index]
          if (filteredBasicInfoQuestions.value.includes(nextAlias)) {
            return nextAlias
          }
          forward ? ++index : --index
        }
        return nextBasicInfoQuestion()
      }
      const unansweredQuestion = filteredBasicInfoQuestions.value.find(
        (alias, index) => {
          if (index == filteredBasicInfoQuestions.value.length - 1) {
            return true
          }
          const questionDefinition = questionsStore.keyedQuestions[alias]
          if (questionDefinition.fields) {
            const field = questionDefinition.fields[0]
            alias = typeof field == 'string' ? field : field.alias
          }
          return !questionsStore.currentAnswers.hasOwnProperty(alias)
        }
      )
      return unansweredQuestion
    }
  })

  const basicInfoQuestionOverride = computed(() => {
    return (alias) => {
      switch (alias) {
        case 'bankruptcy_status':
        case 'fundAspect':
        case 'maxWeeksToFunds':
          return alias + '@boxSelect'
        case 'loanPurpose':
          return alias + '@multiBoxSelect'
        default:
          return alias
      }
    }
  })
  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/
async function setLoadingBasicInfo(x) {
  if (typeof x !== 'number') {
    return
  }
  loadingBasicInfo.value += x
}

async function loadBasicInfoQuestions() {
  const borrowerStore = useBorrowerStore()

  const userStore = useUserStore()

  this.setLoadingBasicInfo(1)
  const actionArray = []
  const authenticated = userStore.hasOptimusUser

  if (!authenticated && process.client) {
    actionArray.push(
      questionsStore.getAnswers(
        { allFields: true },
        borrowerStore.borrowerId
      )
    )
  } else if (authenticated) {
    actionArray.push(matchingStore.getMatches(undefined))
  }
  actionArray.push(
    questionsStore.getQuestionFilters({
      appName: 'marketplace',
    })
  )
  actionArray.push(matchingStore.getFieldsList(undefined))
  await Promise.all(actionArray)
  if (!authenticated && process.client) {
    await matchingStore.getMatches(undefined)
  }
  await questionsStore.getQuestions({
    aliases: matchingStore.qualifiersForAllProducts,
  })
  this.setLoadingBasicInfo(-1)
}

async function loadQuestionsFromQuery() {
  const query = get(useRouter(), 'currentRoute.query')
  const answers = basicInfoSorter.value
    .getHead()
    .concat(basicInfoSorter.value.getTail())
    .concat(['timeInBusiness'])
    .filter((alias) => query.hasOwnProperty(alias))
    .map((alias) => {
      let value = query[alias],
        date,
        parsed
      switch (alias) {
        case 'creditScore':
          value = lsUtils.optionIdToCreditScore(value).max || value
          break
        case 'timeInBusiness':
          date = new Date()
          date.setDate(5)
          parsed = lsUtils.optionIdToTimeInBusiness(value).max
          value = parsed || parsed == 0 ? parsed : value
          if (!isNaN(parseInt(value, 10))) {
            date.setMonth(date.getMonth() - parseInt(value, 10))
          }
          alias = 'business_start_date'
          value = format(date, 'yyyy-MM-dd')
          break
      }
      if (value == 0 || value) {
        return { alias, value }
      }
    })
  answers
    .filter(Boolean)
    .forEach((answer) => questionsStore.updateAnswer(answer))
}

return {
  //STATE
  loadingBasicInfo,

  //GETTERS
  basicInfoWhitelist,
  basicInfoAlwaysAsked,
  basicInfoSorter,
  basicInfoQuestions,
  filteredBasicInfoQuestions,
  nextBasicInfoQuestion,
  basicInfoQuestionOverride,

  //ACTIONS
  setLoadingBasicInfo,
  loadBasicInfoQuestions,
  loadQuestionsFromQuery,
}
})
