
import { iframeOnlyCheck } from '~/libs/iframe-only-check';
import { useIdentityStore } from '~/store/identity';

export default defineNuxtPlugin(({ hook, $pinia }) => {
  // skip if route is /healthcheck
  if (useRoute().path === '/healthcheck') {
    return
  }

  hook('page:loading:start', async () => {
    const identityStore = useIdentityStore($pinia)
    const requestURL = useRequestURL()
    await iframeOnlyCheck({ identityStore, requestURL, log })
  })
})
