<template>
  <section>
    <template v-if="singlePageBasicInfoTestB || singlePageBasicInfoTestC">
      <SinglePageBasicInfoQuestions :data="singlePageBasicInfoTestData" />
    </template>
    <template v-else>
      <div>
        <LoadingHeader
          class="mb-8"
          v-if="showLoading"
          icon-ref="vdjwmfqs"
          icon-path="/images/system-regular-17-assignment-120x120.gif"
          title-text="Loading your application"
          :initial-count="5"
        />
        <BackNavAndMobileHeader
          v-else
          :btnSubmit="getSecondAndPrevious"
          header-title="Basic Info"
          :renderButton="showBackButton"
        />
        <div v-if="showLoading">
          <div class="loading-skeleton basic-info-container overflow-x-hidden">
            <div class="load-box rounded mb-3"></div>
            <div class="load-box rounded mb-3"></div>
            <div class="load-box rounded mb-8"></div>
            <div class="load-button btn btn-sm rounded-5">Next</div>
          </div>
        </div>
        <div
          v-else-if="currentPage"
          class="basic-info-container overflow-x-hidden"
        >
          <header class="basic-info-header marketplace-header mb-5">
            <h3>Basic information</h3>
            <p>
              Let's start with some basic questions so we can find you the right financing options.
            </p>
          </header>
          <basic-info-question
            :class="{
              'basic-info-question-on-page-load':
                !transitioning && !secondLoaded,
              'basic-info-question': !transitioning && secondLoaded,
              'basic-info-question-leave': transitioning,
              'basic-info-question-nav-back': navBackwards
            }"
            :question="hydratedCurrentPage"
            :submitting="submitting"
            :transitioning="transitioning || advancing"
            :is-not-first-question="!isFirstPage"
            @submit="onSubmit(currentPage, $event)"
            @previousQuestion="getPrevious"
            @nextQuestion="setSecondLoaded() && getNext()"
          >
          </basic-info-question>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { mapState } from 'pinia'
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'
import { useApplicationConfigStore } from '~/store/application-config'
import { useExperimentsStore } from '~/store/experiments'
import { useFundingNeedsStore  } from '~/store/funding-needs'
import { useMatchingStore } from '~/store/matching'
import { useProgressStore } from '~/store/progress'
import { useQuestionsStore } from '~/store/questions'
import { useRootStore } from '~/store/root'

import BackNavAndMobileHeader from '~/components/marketplace/subComponents/BackNavAndMobileHeader.vue'
import BasicInfoQuestion from '~/components/basicInfoQuestions/BasicInfoQuestion'
import env from '~/libs/env'
import get from 'lodash/get'
import LoadingHeader from "~/components/marketplace/subComponents/LoadingHeader.vue";
import LoadingSpinner from '~/components/marketplace/subComponents/LoadingSpinner'
import removeTooltip from '../libs/remove-tooltip'
import SinglePageBasicInfoQuestions from '~/components/basicInfoQuestions/SinglePageBasicInfoQuestions'
import SmoothScroll from '~/libs/smooth-scroll'

export default {
  name: 'BasicInfo',
  components: {
    LoadingHeader,
    BackNavAndMobileHeader,
    BasicInfoQuestion,
    LoadingSpinner,
    SinglePageBasicInfoQuestions
  },
  props: {
    v: Object,
    errorText: String
  },
  async setup() {
    const nuxtApp = useNuxtApp()
    const { $lendioCookies } = nuxtApp
    const route = useRoute()

    const rootStore = useRootStore()
    const progressStore = useProgressStore()
    const experimentsStore = useExperimentsStore()

    definePageMeta({
      layout: "marketplace",
      pageTransition: {
        name: 'mp-transition'
      },
      middleware: [
        'transitions-helper',
        'basic-info'
      ],
    });

    let { ref: referrer, franchiseId } = route.query
    referrer = referrer || franchiseId

    const singlePageBasicInfoTestB = ref(false)
    const singlePageBasicInfoTestC = ref(false)

    const { pending } = await useLazyAsyncData(async () => {
      rootStore.updateCurrentPage('basic-info')
      $lendioCookies.setCookiesFromParams(route.query, {
        secure: env('environment') !== 'dev'
      })

      return Promise.allSettled([
        experimentsStore.registerAllExperimentGroups()
      ]).then((results) => results.map((result) => result.status))
    })

    return {
      pending,
      ref: referrer,
      singlePageBasicInfoTestB,
      singlePageBasicInfoTestC,
    }
  },
  data() {
    return {
      secondLoaded: null,
      submitting: false,
      transitioning: false,
      advancing: false,
      showMpModal: false,
      isInvalid: false,
      invalidity: {},
      query: {},
      navBackwards: false,
      singlePageBasicInfoTestData: null,
      mounted: false
    }
  },
  async beforeMount() {
    const appConfigStore = useApplicationConfigStore()
    const rootStore = useRootStore()
    const notCypressUser = !rootStore.isCypressUser

    await appConfigStore.updateBasicInfoModuleTest()

    if (appConfigStore.basicInfoModuleTestEnabled && notCypressUser) {
      return navigateTo({ path: '/marketplace-app/basic-info', query: this.$route.query })
    }
  },
  async mounted() {
    this.mounted = true
    const route = useRoute()
    const questionsStore = useQuestionsStore()

    let basicInfoInitializePromise
    if (!(this.singlePageBasicInfoTestB || this.singlePageBasicInfoTestC)) {
      // This must be done on the client side because it relies on local storage.
      basicInfoInitializePromise = questionsStore.initializeBasicInfoPages({
        route
      })
    }

    if (route.query.toSignup) {
      await this.navToOwnerInfo()
    } else {
      await basicInfoInitializePromise
    }
  },

  //Need to run this action throughout basic-info so if user goes back to industry question and changes it, we can make sure needsMapping updates which determines if the funding-needs page shows.
  async beforeUpdate() {
    useFundingNeedsStore(this.$pinia).getIndustryFromStorage()
  },

  computed: {
    ...mapState(useProgressStore, ['appRoutePrefix', 'currentApp']),
    ...mapState(useAffiliateCustomizationStore, ['preapprovalOnly']),
    ...mapState(useApplicationConfigStore, ['appIdentifierRoute']),
    ...mapState(useQuestionsStore, {
      answers: (store) => get(store, 'answers'),
      loadingQuestions: (store) => get(store, 'loadingQuestions'),
      currentPage: (store) => get(store, 'currentPage'),
      mineral: (store) => get(store, 'mineral'),
      indexedAttributes: (store) => get(store, 'indexedAttributes'),
      isFirstPage: (store) => get(store, 'isFirstPage'),
      isCurrentPageReady: (store) => get(store, 'isCurrentPageReady'),
      isLastPage: (store) => get(store, 'isLastPage')
    }),
    showLoading() {
      return this.pending || (!this.advancing && this.loadingQuestions) || !this.isCurrentPageReady
    },
    showBackButton(){
      return this.isCurrentPageReady && !this.isFirstPage && !this.loadingQuestions
    },
    hydratedCurrentPage() {
      let page = { ...this.currentPage }
      if (this.currentPage?.attributes?.length) {
        page.attributes = this.currentPage.attributes.map((q) => ({
          ...q,
          value:
            get(this.indexedAttributes, `['${q.alias || q.id}'].value`) ||
            q.value
        }))
      }
      return page
    }
  },

  methods: {
    setSecondLoaded() {
      if (this.secondLoaded === null) {
        this.secondLoaded = true
      }
      return true
    },
    getPrevious() {
      if (useRootStore(this.$pinia).pwfSignupInProgress) {
        useRootStore(this.$pinia).setPWFSignupStatus(false)
      }
      // Safety to prevent double prev clicks
      if (!this.transitioning) {
        this.transitioning = true
        this.runAnimation().then(() => {
          useQuestionsStore(this.$pinia).previousPage()
          this.runAnimation()
          this.transitioning = false
        })
      }
    },
    getSecondAndPrevious() {
      this.setSecondLoaded() && this.getPrevious()
      this.navBackwards = true
    },
    getNext(payload) {
      if (useRootStore(this.$pinia).pwfSignupInProgress) {
        useRootStore(this.$pinia).setPWFSignupStatus(false)
      }
      this.navBackwards = false
      const progressStore = useProgressStore(this.$pinia)
      payload = payload || { route: this.$route }
      // Safety to prevent double next clicks
      if (!this.transitioning) {
        if (!this.isLastPage) {
          this.transitioning = true
        } else {
          this.submitting = true
        }
        this.runAnimation().then(async () => {
          SmoothScroll.scrollElementsTop('[data-smooth-scroll="main"]')
          // TODO: Right now lastReleventQuestion works, since we have the 'special page' that always appears last 'fundAspect' and never changes as the last page
          if (!this.isLastPage) {
            useQuestionsStore(this.$pinia).nextPage()
            removeTooltip()
            await this.runAnimation()
          } else {
            this.advancing = true
            if (
              (progressStore.waypointOneForEveryoneTest || useRootStore(this.$pinia).isLoggedIn)
              && !this.preapprovalOnly
            ) {
              await useQuestionsStore(this.$pinia).loadLegacyAnswers()
              await useMatchingStore(this.$pinia).getMatches()
            }
            await progressStore.incrementRoute()
          }
        })
      }
      if (process.client && window.dreams) {
        window.dreams.getBorrowerAnswers()
      }
    },
    onSubmit(question, attributes) {
      return (async () => {
        // Safety to prevent double submissions
        if (!this.submitting) {
          const payload = {
            answers: attributes
          }

          if (useRootStore(this.$pinia).pwfSignupInProgress) {
            useRootStore(this.$pinia).setPWFSignupStatus(false)
          }

          try {
            this.submitting = true
            await useQuestionsStore(this.$pinia).submitFilterAnswers(payload)
          } catch (err) {
            this.submitting = false
            throw err
          }
          this.getNext(payload)
        }
      })()
    },
    runAnimation(seconds = 0.3) {
      const ms = seconds * 1000
      return new Promise((resolve) =>
        setTimeout(() => {
          this.transitioning = false
          this.submitting = false
          resolve()
        }, ms)
      )
    },
    async navToOwnerInfo() {
      let coreRedirect = '/owner-info/owner-info-2'
      if (this.currentApp.type === 'marketplace') {
        //TODO: remove explicitly route to owner-info-2 module after promoting basic info module
        coreRedirect = `/${this.appIdentifierRoute}/owner-info-2`
      }

      if (useRootStore(this.$pinia).isLoggedIn) {
        await navigateTo(this.appRoutePrefix + coreRedirect)
      } else {
        let redirectURI = this.appRoutePrefix + '/owner-info?experience=marketplace'
        if (
          (this.qualTestA || this.qualTestB) &&
          (this.mineral === 'Gold' ||
            this.mineral === 'Diamond' ||
            this.mineral === 'Platinum')
        ) {
          redirectURI = '/qualifier'
        }
        if (this.mineral) {
          questionsHelper.setStorageItem('mineral', null, this.mineral)
          redirectURI = redirectURI.includes('?')
            ? redirectURI + '&mineral=' + this.mineral
            : redirectURI + '?mineral=' + this.mineral
        }
        if (this.ref) {
          redirectURI = redirectURI.includes('?')
            ? redirectURI + '&ref=' + this.ref
            : redirectURI + '?ref=' + this.ref
        }
        await navigateTo(redirectURI)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.basic-info-header {
  animation: slide-fade 0.25s;
}
.basic-info-question-on-page-load {
  opacity: 0;
  animation: slide-fade 0.25s forwards;
   position: relative;
}
.basic-info-question {
  position: relative;
  opacity: 0;
  animation: slide-fade 0.25s forwards;
}
.basic-info-question-nav-back {
  animation: slide-fade-backwards 0.25s forwards;
}
.basic-info-question-leave {
  position: relative;
  animation: fade-out 0.15s forwards;
}
@keyframes slide-fade {
  from {
    opacity: 0;
    right: -360px;
  }
  to {
    opacity: 1;
    right: 0;
  }
}
@keyframes slide-fade-backwards {
  from {
    opacity: 0;
    left: -360px;
  }
  to {
    opacity: 1;
    left: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.app-page {
  transition: opacity 0.3s;
  margin: 20px 0;
  width: 100%;
  padding: 25px;
  @include media-breakpoint-up(md) {
    width: 100%;
  }
  @include media-breakpoint-up(lg) {
    margin: 25px 5%;
    padding: 40px;
  }
  @include media-breakpoint-up(xl) {
    margin: 25px 10%;
  }
  h3 {
    text-align: center;
  }
}

.basic-info-container {
  margin: 0 -2px;
  padding: 0 2px;
  min-height: 475px;
}

.load-box {
  height: 50px;
  width: 100%;
  @include media-breakpoint-up(lg) {
    width: 75%;
  }
}

.load-button {
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}
</style>
