import { defineStore } from 'pinia'
import env from '~/libs/env'

import { useBorrowerStore } from '~/store/borrower'
import { useRootStore } from '~/store/root'

// Helper functions
function parseOption(option = {}) {
  if (option.alternate_product_content) {
    return parseAltOptionContent(option)
  }

  if (option.lender_product_content) {
    return parseLenderProductContent(option)
  }

  return {}
}
function parseAltOptionContent(option = {}) {
  const content = option.alternate_product_content
  const json = JSON.parse(content.json)
  let returnData = json

  if (!returnData['title']) returnData['title'] = content.title
  if (!returnData['imageUrl']) returnData['imageUrl'] = content.imageUrl
  if (!returnData['clickUrl']) returnData['clickUrl'] = content.clickUrl
  if(returnData['marketplaceClickUrl']) returnData['clickUrl'] = returnData['marketplaceClickUrl']

  return returnData
}
function parseLenderProductContent(option = {}) {
  const content = option.lender_product_content
  return {
    title: content.lender.name + ' - ' + content.name,
    description: content.category.description,
    ctaType: 'checkbox',
    cta:
      'Yes, I would like to talk with a ' +
      content.lender.name +
      ' representative.',
    imageUrl: content.lender.has_logo
      ? '/images/lender-logos/lender-' + content.lender.id + '.png'
      : '/images/lender-logos/lender_logo_generic.png',
    alert:
      'A representative from ' +
      content.lender.name +
      ' will be reaching out to you shortly.'
  }
}
function includeTrackingData(clickUrl, trackingParams = {}) {
  if (!trackingParams || !Object.keys(trackingParams).length) {
    return clickUrl;
  }

  const url = new URL(clickUrl)
  const trackingKeys = Object.keys(trackingParams)
  const params = trackingKeys.map((key) => `${key}=${trackingParams[key]}`).join('&')
  if (!url.search) {
    url.search = `?${params}`
  } else {
    const replaceRegex = new RegExp(`&?(${trackingKeys.join('|')})=([^&]*)&?`, 'g')
    url.search = url.search.replace(replaceRegex, '')

    const searchParts = [params]
    const currentSearch = url.search.replace(/^\?/, '')
    if (currentSearch) {
      searchParts.push(currentSearch)
    }

    url.search = `?${searchParts.join('&')}`
  }

  return url.toString()
}
// End helper functions

export const useOptInsStore = defineStore('optIns', () => {
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp

  const borrowerStore = useBorrowerStore()
  const rootStore = useRootStore()

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const allOptions = ref([])
  const options = ref([]) // non-financing options (e.g., credit cards)
  const financingOptions = ref([])
  const loading = ref(false)
  const leadId = ref(null)
  const error =  ref(null)
  const loaded = ref(false)

  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/

  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

function setOptInsSuccess(_allOptions, addMore = false) {
  let allData = _allOptions
  if (!addMore) {
    loading.value = false
  } else {
    allData = [...(allOptions.value || []), ..._allOptions]
  }

  allOptions.value = allData

  financingOptions.value = allData.filter(
    (option) => option.productType === 'financing'
  )
  options.value = allData.filter(
    (option) => option.productType !== 'financing'
  )
}

async function optInsSuccess(cards, addMore = false) {
  let _allOptions = cards.map((option) =>
    Object.assign({ markingInterested: false }, option, parseOption(option))
  )

  // if borrower.stateId is NC, OR exclude Lexington Law from them
  if (
    borrowerStore.borrower &&
    borrowerStore.borrower.stateId
  ) {
    if (
      ['NC', 'OR'].includes(
        borrowerStore.borrower.stateId
      )
    ) {
      const LEXINGTON_LAW_ALT_OPTION_ID = 2
      _allOptions = _allOptions.filter(
        (o) => o.id !== LEXINGTON_LAW_ALT_OPTION_ID
      )
    }
  }

  this.setOptInsSuccess(_allOptions, addMore)
}

  async function getOptInOffers(force = false) {
    if (options.value && financingOptions.value && loaded.value && !force) {
      return
    }

    if (!borrowerStore.borrowerId) {
      log.error(
        'opt-in could not be retrieved because of null or undefined borrowerId on rootState',
        {
          borrowerId: borrowerStore.borrowerId,
          borrower: borrowerStore.borrower
        }
      )
      return
    }

    loading.value = true
    await $axios
      .get(
        `${env('apiUrl')}/alt-option-list/${
          borrowerStore.borrowerId
        }`,
      )
      .then(async (res) => {
        await this.optInsSuccess(Object.values(res.data.data))
        loaded.value = true
      })
      .catch((err) => {
        loading.value = false
        error.value = err
        return
      })
  }

    function markInterested(altProductId) {
      const option = options.value.find((option) => option.id === altProductId)
      if (option) {
        option.markingInterested = true
      }

      const financingOption = financingOptions.value.find(
        (option) => option.id === altProductId
      )
      if (financingOption) {
        financingOption.markingInterested = true
      }
    }

    function clickedOnCallToAction(altProductId, data) {
      const clickedTime = data?.clickedOnCallToAction ?? (new Date()).toISOString()
      const option = options.value.find((option) => option.id === altProductId)
      if (option) {
        option.clickedOnCallToAction = true
        option.clickedAt = clickedTime
      }

      const financingOption = financingOptions.value.find(
        (option) => option.id === altProductId
      )
      if (financingOption) {
        financingOption.clickedOnCallToAction = true
        financingOption.clickedAt = clickedTime
      }
    }

    function finishedMarkingInterested(altProductId) {
      const option = options.value.find((option) => option.id === altProductId)
      if (option) {
        option.markingInterested = false
      }

      const financingOption = financingOptions.value.find(
        (option) => option.id === altProductId
      )
      if (financingOption) {
        financingOption.markingInterested = false
      }
    }

    async function interested(altProductId, fromOffersPage = false, trackingData = {}, openInNewTab = true) {
      const option = options.value.find((option) => option.id === altProductId)
      const financingOption = financingOptions.value.find(
        (option) => option.id === altProductId
      )
      this.markInterested(altProductId)

      let hasStaticUrl = false
      const openLink = (url) => {
        if (openInNewTab) {
          window.open(url, '_blank')
        } else {
          window.location.href = url
        }
      }
      if (option && option.clickUrl) {
        const clickUrl = fromOffersPage && option.mpOffersClickUrl
          ? option.mpOffersClickUrl
          : option.clickUrl
        hasStaticUrl = true
        openLink(includeTrackingData(clickUrl, trackingData))
      }
      if (financingOption && financingOption.clickUrl) {
        const clickUrl = fromOffersPage && financingOption.mpOffersClickUrl
          ? financingOption.mpOffersClickUrl
          : financingOption.clickUrl
        hasStaticUrl = true
        openLink(includeTrackingData(clickUrl, trackingData))
      }

      const offer = option || financingOption
      const hasAlert = Boolean(offer && offer.alert)
      let newTab
      if (!hasStaticUrl && !hasAlert && openInNewTab) {
        newTab = window.open('', '_blank')
      }
      const closeTab = () => {
        if (!newTab) {
          return;
        }
        newTab.close()
        newTab = null
      }

      const data = {
        leadId: leadId.value
      }
      await $axios
        .post(
          `${env('apiUrl')}/alt-option-interested/${altProductId}/borrower/${
            borrowerStore.borrowerId
          }`,
          JSON.stringify(data),
          { headers: { 'Content-Type': 'application/json' } }
        )
        .then((res) => {
          const altOptInterested = res?.data?.data
          this.clickedOnCallToAction(altProductId, altOptInterested)

          if (newTab) {
            if (altOptInterested?.clickUrl) {
              newTab.location.href = altOptInterested.clickUrl
            } else {
              closeTab()
            }
          } else if (!openInNewTab && altOptInterested?.clickUrl) {
            window.location.href = altOptInterested.clickUrl
          }
        })
        .catch((err) => {
          closeTab()
          log.error('Failed to mark opt-in as interested', {
            altProductId,
            err
          })
        })

      this.finishedMarkingInterested(altProductId)
    }

    async function viewed(altProductId) {
      $axios
        .post(`${env('apiUrl')}/alt-option-view/${altProductId}`)
        .catch((err) => {
          if (err.response && err.response.status >= 500) {
            log.error(`Failed to mark opt-in as viewed`, { altProductId, err })
          }
        })
    }

    async function addOptions(options) {
      const existingIds = (allOptions.value || []).map((opt) => opt.id)
      const newOptions = (options || []).filter(opt => !existingIds.includes(opt.id))

      if (newOptions.length) {
        await this.optInsSuccess(newOptions, true)
      }
    }

    return {
      //STATE
      allOptions,
      options, // non-financing options (e.g., credit cards)
      financingOptions,
      loading,
      leadId,
      error,
      loaded,

      //GETTERS

      //ACTIONS
      setOptInsSuccess,
      optInsSuccess,
      getOptInOffers,
      markInterested,
      clickedOnCallToAction,
      finishedMarkingInterested,
      interested,
      viewed,
      addOptions,
    }
})
