// Library to get default or custom styles for any app
export function getStyleProps(customizations) {
  let defaultStyles = {
    appAnchorTagColor: '#1270ED',
    appAsideBackgroundColor:'#303A45',
    appBodyBackgroundColor: '#FFFFFF',
    appBodyHeaderFontColor: '#393A3D',
    appBodyHeaderFontSize: 24,
    appBodyHeaderFontWeight: 'bold',
    appBodyPrimaryFontColor: '#6B717A',
    appBodyPrimaryFontSize: 14,
    appBodyPrimaryFontWeight: 'normal',
    appButtonBorderRadius: 100,
    appButtonFontWeight: 'normal',
    appButtonPaddingX: 48,
    appButtonPaddingY: 12,
    appButtonBackgroundColor: '#1270ED',
    appButtonFontColor: '#FFFFFF',
    appButtonFontSize: 14,
    appButtonShadowVisible: false,
    appCardBorderColor: '#E2DBD5',
    appCardBorderRadius: 15,
    appCardLabelBackgroundColor: '#0D2748',
    appCardLabelFontColor: '#FFFFFF',
    appFontFamilyBody: 'inherit',
    appFontFamilyBold: 'inherit',
    appFormFieldBackgroundColor: '#FFFFFF',
    appFormFieldLabelColor: '#393A3D',
    appFormFieldValueColor:'#393A3D',
    appNavCircleCompleted: '#1270ED',
    appNavCircleDisabled: '#77828D',
    appNavCirclePrimary: '#1270ED',
    appNavCircleTextDisabled: '#FFFFFF',
    appNavCircleTextCompleted: '#FFFFFF',
    appNavCircleTextPrimary: '#FFFFFF',
    appNavTextCompleted: '#FFFFFF',
    appNavTextDisabled: '#77828D',
    appNavTextPrimary: '#FFFFFF',
    appPrimaryColor: '#1270ED',
    appSecondaryColor: '#FAB42D',
    appAmountFontSize: 32,
    appAmountFontWeight: 600,
    appAmountAlignment: 'center',
  };

  let customStyles = customizations
    ? { ...defaultStyles, ...customizations }
    : { ...defaultStyles }

  return {
    '--app-anchor-tag-color': customStyles.appAnchorTagColor,
    '--app-aside-background-color': customStyles.appAsideBackgroundColor,
    '--app-aside-active-background-color': customStyles.appAsideActiveBackgroundColor || customStyles.appAsideBackgroundColor,
    '--app-body-background-color': customStyles.appBodyBackgroundColor,
    '--app-body-header-font-color': customStyles.appBodyHeaderFontColor,
    '--app-body-header-font-size': customStyles.appBodyHeaderFontSize + 'px',
    '--app-body-header-font-weight': customStyles.appBodyHeaderFontWeight,
    '--app-body-primary-font-color': customStyles.appBodyPrimaryFontColor,
    '--app-body-primary-font-size': customStyles.appBodyPrimaryFontSize + 'px',
    '--app-body-primary-font-weight': customStyles.appBodyPrimaryFontWeight,
    '--app-button-background-color': customStyles.appButtonBackgroundColor,
    '--app-button-border-radius': customStyles.appButtonBorderRadius + 'px',
    '--app-button-font-color': customStyles.appButtonFontColor,
    '--app-button-font-size': customStyles.appButtonFontSize + 'px',
    '--app-button-font-weight': customStyles.appButtonFontWeight,
    '--app-button-padding-x': customStyles.appButtonPaddingX + 'px',
    '--app-button-padding-y': customStyles.appButtonPaddingY + 'px',
    '--app-button-shadow-visible': customStyles.appButtonShadowVisible,
    '--app-card-border-color': customStyles.appCardBorderColor,
    '--app-card-border-radius': customStyles.appCardBorderRadius + 'px',
    '--app-card-label-background-color': customStyles.appCardLabelBackgroundColor,
    '--app-card-label-font-color': customStyles.appCardLabelFontColor,
    '--app-font-family-body': customStyles.appFontFamilyBody,
    '--app-font-family-bold': customStyles.appFontFamilyBold,
    '--app-form-field-background-color': customStyles.appFormFieldBackgroundColor,
    '--app-form-field-label-color': customStyles.appFormFieldLabelColor,
    '--app-form-field-value-color': customStyles.appFormFieldValueColor,
    '--app-nav-circle-disabled': customStyles.appNavCircleDisabled,
    '--app-nav-circle-completed': customStyles.appNavCircleCompleted,
    '--app-nav-circle-primary': customStyles.appNavCirclePrimary,
    '--app-nav-circle-text-disabled': customStyles.appNavCircleTextDisabled,
    '--app-nav-circle-text-completed': customStyles.appNavCircleTextCompleted,
    '--app-nav-circle-text-primary': customStyles.appNavCircleTextPrimary,
    '--app-nav-text-disabled': customStyles.appNavTextDisabled,
    '--app-nav-text-completed': customStyles.appNavTextCompleted,
    '--app-nav-text-primary': customStyles.appNavTextPrimary,
    '--app-primary-color': customStyles.appPrimaryColor,
    '--app-primary-400': customStyles.appPrimaryColor,
    '--app-primary-300': customStyles.appPrimaryColor,
    '--app-primary-200': customStyles.appPrimaryColor,
    '--app-primary-100': customStyles.appPrimaryColor,
    '--app-secondary-color': customStyles.appSecondaryColor,
    '--app-amount-font-size': customStyles.appAmountFontSize + 'px',
    '--app-amount-font-weight': customStyles.appAmountFontWeight,
    '--app-amount-alignment': customStyles.appAmountAlignment,
    '--app-focus-color': customStyles.appPrimaryColor + 80,
  }
}

export const DEFAULT_THEME_COLOR = '#00bff0';
