import { defineStore } from 'pinia'
import { localStorageService } from '~/libs/local-storage.service'
import { MBOX_DETAILS } from '~/libs/adobe-target'
import { stringify } from 'tiny-querystring'
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'
import { useAffiliateStore } from './affiliate'
import { useAppAnalyticsStore } from '~/store/app-analytics'
import { useBorrowerStore } from '~/store/borrower'
import { useCobrandStore } from '~/store/cobrand'
import { useExperienceStore } from '~/store/experience'
import { useExperimentsStore } from '~/store/experiments'
import { useProgressStore } from '~/store/progress'
import { useQuestionsStore } from '~/store/questions'
import { useUserStore } from '~/store/user'
import BorrowerRouting from '~/libs/borrower-routing'
import env from '~/libs/env'
import get from 'lodash/get'
import PublicLenders from '~/cypress/fixtures/publicLenders.json'
import routeAuth from '~/libs/route-authentication'
import TrackingService from '~/libs/tracking-service'
import VisitorTracker from '~/libs/visitor-tracker'
import { checkUrlSafety } from '~/libs/external-redirect-lib'
import zipObject from 'lodash/zipObject.js'
import TimeoutWorker from '~/src/workers/timeoutWorker?worker&inline'
import { useIdentityStore } from '~/store/identity'
import { DEFAULT_THEME_COLOR } from '~/libs/customizations'
import { ulid as getUlid } from 'ulid'

function redirectWithParams(path, query) {
  // Set the default redirect path to /login
  let redirectPath = '/login'

  let queryString = query
    ? '?' + encodeURIComponent(stringify(query).replace(/\?/g, '&'))
    : ''
  // Append any query parameters that were on their previous route they tried to go to
  redirectPath += '?r=' + path + queryString

  return redirectPath
}

function cleanUri(uri) {
  if (!uri) {
    return ''
  }
  return uri.replace('/bp', '')
}

export const useRootStore = defineStore('root', () => {
  const nuxtApp = useNuxtApp()
  const { $lendioCookies, $axios } = nuxtApp
  const router = useRouter()

  const appAnalyticsStore = useAppAnalyticsStore()
  const borrowerStore = useBorrowerStore()
  const cobrandStore = useCobrandStore()
  const experimentsStore = useExperimentsStore()
  const experienceStore = useExperienceStore()
  const affiliateStore = useAffiliateStore()
  const userStore = useUserStore()
  const questionsStore = useQuestionsStore()
  const progressStore = useProgressStore()
  const affiliateCustomizationStore = useAffiliateCustomizationStore()
  const identityStore = useIdentityStore()

/*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const isCypressUser = ref(null) // null | boolean
  const activationId = ref('')
  const anonymousId = ref(null)
  const authUser = ref(null)
  const authToken = ref(null)
  const authTokenExpiry = ref(null)
  const expiryTimeoutSet = ref(false)
  const displayExpiryModal = ref(false)
  const affiliateId = ref(null)
  const isEmbedded = ref(null)
  const embeddedJwt = ref(null)
  const intercomHash = ref(null)
  const facebookAuth = ref({
    status: '',
    userData: null
  })
  const pageTitle = ref('Lendio')
  const favicons = ref([
    {
      rel: 'shortcut icon',
      href: 'images/blank-favicon.ico',
      type: 'image/x-icon'
    }
  ])
  const dynamicAppProgress = ref({})
  const renewalAppProgress = ref({})
  const currentPage = ref(null)
  const marketingOwnership = ref(null)
  const validMarketingOwnershipResponse = ref(false)
  const currentBorrowerInterest = ref(null)
  const publicLenderList = ref([])
  const useLegacyDesign = ref(true)
  const tenantId = ref(null)
  const testing = ref('testing-value')
  const pwfSignupInProgress = ref(false)
  const visitorId = ref(null)

/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/
  const getEmbeddedInitBody = computed(() => {
    const jwt = embeddedJwt.value
    const user = authUser.value

    if (!user || !jwt) {
      return null
    }

    return {
      jwt: jwt,
      user: {
        login: user.email,
      },
    }
  })

  const canUseEmbedded = computed(() => {
    return authUser.value && !isEmbedded.value && embeddedJwt.value
  })

  const getPublicLenderList = computed(() => {
    return publicLenderList.value
  })

  const getAffiliateId = computed(() => {
      return affiliateId
  })

  const naicsMarketplace = computed(() => {
    return appAnalyticsStore.hasTargetOffer(
      MBOX_DETAILS.TEST_NAICS_MARKETPLACE.NAME,
      'naicsMarketplace'
    )
  })

  const userProfile = computed(() => {
    const _authUser = authUser.value
    if (_authUser) {
      return _authUser
    }
    return null
  })

  const isLoggedIn = computed(() => {
    return !!authToken.value
  })

/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  function trimStringQuotes(val) {
    // Cookies should always be saved as strings
    const str = typeof val === 'string'
      ? val
      : JSON.stringify(val)

    // Guard against strings that have been encoded twice
    // This fixed an auth issue where the token was being
    // saved as a cookie with encoded quotes but only for
    // a specific deauthentication event
    const match = str.match(/^"(.*)"$/)
    if (match) {
      const [_, contents] = match
      return contents
    }
    return str
  }

  /**
   * @param {string} token
   * @param {string} type - 'BEARER' by default. Use 'LENDIO_JWT' when the token being stored is a lendio-jwt from Identity Service.
   * @returns {Promise<void>}
   */
  async function setAuthToken(token, type = 'BEARER') {
    authToken.value = token
    const tokenCheck = token && await $lendioCookies.get('token') !== token
    if (tokenCheck) {
      await $lendioCookies.set('token', this.trimStringQuotes(token), { path: isEmbedded.value ? '/bp/embedded' : '/bp/', expires: 0.5 })
      await $lendioCookies.set('authType', type, { path: isEmbedded.value ? '/bp/embedded' : '/bp/', expires: 0.5 })

      if (type === 'LENDIO_JWT') {
        try {
          const expiration = JSON.parse(atob(token.split('.')[1])).exp
          authTokenExpiry.value = expiration
        } catch (e) {
          log.warning('Error parsing Lendio JWT', e)
        }
      }
    }
  }

  function setVisitorId(_visitorId) {
    visitorId.value = _visitorId
  }

  async function setIsEmbedded(_isEmbedded) {
    isEmbedded.value = _isEmbedded
    if (_isEmbedded && $lendioCookies.get('isEmbedded') !== _isEmbedded) {
      await $lendioCookies.set('isEmbedded', _isEmbedded, { path: _isEmbedded ? '/bp/embedded' : '/bp/', expires: 0.5 })
    }
  }

  async function setBorrowerId(borrowerId) {
    borrowerStore.setNewBorrowerId(borrowerId)
    await $lendioCookies.set('borrowerId', borrowerId, { path: isEmbedded.value ? '/bp/embedded' : '/bp/', expires: 0.5 })
  }

  async function setEmbeddedToken(_embeddedJwt)
  {
    embeddedJwt.value = _embeddedJwt
    if (_embeddedJwt && await $lendioCookies.get('embedded_jwt') !== _embeddedJwt) {
      await $lendioCookies.set('embedded_jwt', this.trimStringQuotes(_embeddedJwt), { path: '/bp/', expires: 1 })
    }
  }

  async function setIntercomHash(value)
  {
    intercomHash.value = value
    if (value && await $lendioCookies.get('intercom_hash') !== value) {
      await $lendioCookies.set('intercom_hash', this.trimStringQuotes(value), { path: '/bp/', expires: 1 })
    }
  }

  async function setAnonymousId(refresh = false) {
    const keyVal = 'anonymousId'
    const ulid = refresh ? getUlid() : $lendioCookies.get(keyVal) || getUlid()
    $lendioCookies.set(keyVal, ulid, { expires: 90 })
    anonymousId.value = ulid
    return ulid
  }

  async function getIntercomHash(payload = {})
  {
    if (env('environment') !== 'LIVE' && !env('debugChat')) {
      return
    }

    if (intercomHash.value && !payload.forceReload) return intercomHash.value;

    // We must have either a user or lendioId to get a hash
    if (!authUser && !appAnalyticsStore.getLendioId) return;

    let path = '/intercom/gethash/'

    if (!authUser) {
      path.concat(appAnalyticsStore.getLendioId)
    }

    return await $axios.get(`${env('apiUrl')}${path}`)
      .then(res => {
        const resData = get(res, 'data.data')
        intercomHash.value = resData;
        return resData
      })
      .catch(err => {
        log.warning('[Intercom] - Error Fetching Intercom API hash.')
      })
  }

  async function destroyUser() {
    authUser.value = null
    authToken.value = null
    embeddedJwt.value = null
    intercomHash.value = null
    anonymousId.value = null

    $lendioCookies.remove('token', { path: isEmbedded.value ? '/bp/embedded' : '/bp/' })
    $lendioCookies.remove('anonymousId', { path: isEmbedded.value ? '/bp/embedded' : '/bp/' })
    $lendioCookies.remove('borrowerId', { path: isEmbedded.value ? '/bp/embedded' : '/bp/' })
    $lendioCookies.remove('embedded_jwt', { path: '/bp/' })
    $lendioCookies.remove('intercom_hash', { path: '/bp/' })
    $lendioCookies.remove('authType', { path: '/bp/' })

    if (env('intercomChat') === 'true') {
      if (window.Intercom) {
        window.Intercom('shutdown')
      }
    }
  }

  // TODO - determine if this code is still needed or can be removed without consequence
  // veejones has created a card for the app team to review this code
  function destroyCookiesAndLocalStorage(path) {
    const routesToDestroyAllCookies = [
      /\/covid-relief\//,
      // TODO: The routes below covid-relief should probably be removed from this when the ppp app goes away! :)
      // But for now, it was a 'better safe than sorry' situation
      /\/login/,
      /\/basic-info/,
      /\/owner-info/,
      /\/profile/,
      /\/upload/,
      /\/documents/,
      /\/404/
    ]
    const onPreserveRoute = !routesToDestroyAllCookies.some((pattern) =>
      pattern.test(path)
    )
    const cookiesToPreserve = onPreserveRoute
      ? [
          // patterns to preserve
          /^questionHistory/,
          /^appProgress/,
          /^dreams/,
          /^rememberedIdentity/,
        ]
      : [
        /^dreams/,
        /^rememberedIdentity/,
      ]
    if (process.client) {
      // preserve some local storage items
      const preservedItems = [...Array(localStorage.length)]
        .map((_, i) => localStorage.key(i))
        .filter((key) =>
          cookiesToPreserve.some((pattern) => pattern.test(key))
        )
        .reduce((collect, key) => {
          return Object.assign(collect, {
            [key]: localStorageService.getItem(key)
          })
        }, {})

      // clear local storage
      localStorageService.clear()

      // restore preserved items
      Object.keys(preservedItems).forEach((key) =>
        localStorageService.setItem(key, preservedItems[key])
      )
    }
  }

  async function getTokenExpiration() {
    const Authorization = userStore.getAuthHeader()
    if (!Authorization) {
      return null
    }

    if (authToken.value && $lendioCookies.get('authType') === 'LENDIO_JWT') {
      try {
        const expiration = JSON.parse(atob(authToken.value.split('.')[1])).exp
        authTokenExpiry.value = expiration
        return authTokenExpiry.value
      } catch (e) {
        log.warning('Error parsing Lendio JWT', e)
      }
    }

    return await $axios.get(`${env('apiUrl')}/authorization/token-expiration`)
      .then(res => {
        const response = res?.data?.data
        if (!response) { return null }

        const expiry = response?.access_token_expires_timestamp
        if (!expiry) { return null }
        authTokenExpiry.value = expiry
        return expiry
      })
      .catch((err) => {
        log.warning('Token expiration check failed', err)
        return null
      })
  }

  async function setupTokenExpirationTimeout({ expiryArg = null, delayStart = 0 } = {}) {
    // Option to delay the start of method execution to allow the
    // extend sesssion job time to complete prior to checking expiration.
    // delayStart should be provided as value in seconds.
    if (delayStart) {
      const delayStartMs = delayStart * 1000

      expiryTimeoutSet.value = true
      const delayTimeoutWorker = new TimeoutWorker()
      delayTimeoutWorker.onmessage = () => {
        expiryTimeoutSet.value = false
        delayTimeoutWorker.terminate()
        this.setupTokenExpirationTimeout({ expiryArg })
      }
      delayTimeoutWorker.postMessage(delayStartMs)

      return
    }
    const expiry = expiryArg ?? await this.getTokenExpiration()
    if (!expiry) { return false }

    // Formatted TS to get delay MS for setTimeout
    const showModalDate = new Date(expiry * 1000)
    const subMinutes = 6
    showModalDate.setMinutes(showModalDate.getMinutes() - subMinutes)
    const showModalDateTs = showModalDate.getTime()
    const nowTs = Date.now()
    // Ensure that if they click continue session we wait up to minimum time before checking again
    const minimumDelayMs = 30 * 1000
    const delay = Math.max(minimumDelayMs, showModalDateTs - nowTs)

    expiryTimeoutSet.value = true
    const coreTimeoutWorker = new TimeoutWorker()
    // Evaluation function for setTimeout and modal trigger
    coreTimeoutWorker.onmessage = async () => {
      expiryTimeoutSet.value = false
      coreTimeoutWorker.terminate()
      const callbackExpiry = await this.getTokenExpiration()
      if (callbackExpiry && callbackExpiry > expiry) {
        this.setupTokenExpirationTimeout({ expiryArg: callbackExpiry })
      } else {
        this.setDisplayExpiryModal(true)
      }
    }
    coreTimeoutWorker.postMessage(delay)

    return expiryTimeoutSet.value
  }

  function setDisplayExpiryModal(value) {
    if (displayExpiryModal.value === value) {
      return
    }
    displayExpiryModal.value = value
  }

  /**
   * Capture tenantId from query param
   */
  async function setTenantIdFromRoute({ route }) {
    if (!route.path.startsWith('/embedded')) {
      return
    }

    if (!route.query.tenantId) {
      return
    }

    // capture tenantid from query param
    const value = parseInt(route.query.tenantId)
    tenantId.value = value
    $lendioCookies.set('tenantId', value, { path: isEmbedded.value ? '/bp/embedded' : '/bp/', expires: 0.5 })
  }

  /**
   * @returns {string | null} Redirect URL
   */
  async function nuxtServerInit({
    route,
    $config,
    $lendioCookies,
    $pinia,
  }) {
    global.env = $config
    if ($config.debugEnabled === 'true') {
      console.log('assigned $config to global', global.env)
    }

    // load customizations for tenantId before cookie access
    await this.setTenantIdFromRoute({ route })

    // Only do this server side.
    if (process.server && !authToken.value) {
      // get auth values from cookies
      const accessToken = $lendioCookies.get('accessToken')
      const _embeddedJwt = $lendioCookies.get('embedded_jwt')
      const _intercomHash = $lendioCookies.get('intercom_hash')
      let token = $lendioCookies.get('token')
      let _isEmbedded = $lendioCookies.get('isEmbedded')
      let borrowerId = null

      // override auth values from embedded route params
      if (route.path.startsWith('/embedded')) {
        if (route.query.token) {
          token = route.query.token
        }
        if (route.query.isEmbedded) {
          // query param comes through as string (verified with logging) and we want boolean in state
          _isEmbedded = route.query.isEmbedded === 'true'
        }
        if (route.query.borrowerId) {
          borrowerId = route.query.borrowerId
        }
      }

      if (_isEmbedded) {
        this.setIsEmbedded(_isEmbedded)
        if (borrowerId) {
          this.setBorrowerId(borrowerId)
        }
      }
      if (token) {
        this.setAuthToken(token)
      }
      if (_embeddedJwt) {
        this.setEmbeddedToken(_embeddedJwt)
      }
      if (_intercomHash) {
        this.setIntercomHash(_intercomHash)
      }
      if (accessToken) {
        userStore.setAccessToken(accessToken)
      }

      if (route.query.affiliateCustomizationsId) {
        $lendioCookies.set('affiliateCustomizationsId', route.query.affiliateCustomizationsId, { path: '/bp/sba-complete/' });
      }

      const redirectURL = await this.authenticate({ route, $pinia, borrowerId })
      return redirectURL
    }
  }

  /**
   * @returns {string | null} Redirect URL
   */
  async function authenticate({ route, $pinia }) {
    const token = authToken.value
    const _isEmbedded = isEmbedded.value

    if ((isLoggedIn.value || _isEmbedded) && token && authUser.value) {
      // Initialize the embedded application
      if (!process.server && !_isEmbedded && window.lendio && embeddedJwt.value) {
        const initBody = borrowerStore.embeddedInitBody

        if (initBody) {
          window.lendio.initialize(initBody)
        }
      }
      return // Don't check auth if the user is set and we have a token
    }

    const mobileLendioToken = $lendioCookies.get('mobile-lendio-jwt')
    const isMobile = $lendioCookies.get('isMobile')

    if (mobileLendioToken && isMobile) {
      await this.setAuthToken(mobileLendioToken, 'LENDIO_JWT')
      await this.getCurrentUser({ forceReload: true })
    }

    if (token && !authUser.value) {
      await this.authenticateCurrentUser({
        forceReload: true
      })
    }

    const isTest = authUser.value
      && authUser.value.email?.includes('@lendio.com')
      && authUser.value.email?.includes('lendiotest')

    const isAllowedToTest = (
      isTest
      || $lendioCookies.get('allowTestRoutes') == true
      // || env('environment') === 'dev'
    )

    if (routeAuth.isTestOnlyRoute(route) && !isAllowedToTest) {
      if (_isEmbedded) {
        throw createError({
          statusCode: 401,
          statusMessage: 'Unauthorized Action',
          data: {
            isEmbedded: true,
            location: 'Authenticate - Test Only'
          },
          fatal: true
        })
      }

      if (authUser.value) {
        throw createError({
          statusCode: 404,
          message: `Not found: ${route.path}`,
          fatal: true
        })
      }
    }

    // redirect to login if no token present
    if (!isLoggedIn.value && !routeAuth.isPublicRoute(route, route.query)) {
      await this.destroyUser()
      userStore.clearAccessToken()
      let redirectURL = redirectWithParams(route.path, route.query) // Redirect if not login page

      if (_isEmbedded) {
        throw createError({
          statusCode: 401,
          statusMessage: 'Unauthorized Action',
          data: {
            isEmbedded: true,
            location: 'Authenticate'
          },
          fatal: true
        })
      }

      // const authTokenStore = authToken.value ?? null
      // const authTokenCookie = $lendioCookies.get('token') ?? null
      // const authTokenCookieRes = $lendioCookies.get('token', { fromRes: true }) ?? null
      // const errorObj = {
      //   location: 'AUTHENTICATE',
      //   isEmbedded: _isEmbedded,
      //   authTokenStore: !!authTokenStore,
      //   authTokenCookie: !!authTokenCookie,
      //   authTokenCookieRes: !!authTokenCookieRes,
      //   tokensMatch: authToken.value === authTokenCookie,
      //   tokensMatchRes: authToken.value === authTokenCookieRes,
      //   serverReq: !!process.server,
      //   redirectURL
      // }
      // log.info('AUTHENTICATION FAILURE:' + JSON.stringify(errorObj))

      return redirectURL
    }
    return
  }

  /**
   * Pull activationId from localStorage and set them into state
   */
  function getActivationIdFromStorage() {
    // localStorageService handles checking for existence of the service and
    // falls back to saving in an object, allowing us to remove the check that
    // was previously here: `if(typeof localStorage === 'undefined') return`.
    const _activationId = localStorageService.getItem('activationId')
    // set activationId in store
    if (_activationId) {
      activationId.value = _activationId
    }
  }

  async function getCurrentUser(payload = {}) {
    if (authUser.value && !payload.forceReload) return authUser.value
    const _originalToken = authToken.value
    const hadOriginalToken = !!_originalToken
    try {
      if (hadOriginalToken) {
        const Authorization = userStore.getAuthHeader({ silent: false })
        if (!Authorization) {
          return
        }
        const { data, status, ok, statusText } = await $axios.get(
          `${env('bpApiUrl')}/user/currentUser`,
          {
            token: _originalToken,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization
            }
          }
        )
        if (!data.data || status !== 200) {
          // If the token is not the most recent token and the current token is not the basic token, this may be a late request and may not be needed
          if (authToken.value !== _originalToken && authUser.value) {
            return authUser.value
          }
          await this.destroyUser() // wipeout the user/token
          await userStore.clearAccessToken()
          if (
            hadOriginalToken ||
            (!payload.bypassRedirect &&
              (!payload.route ||
                !routeAuth.isPublicRoute(payload.route, payload.route.query)))
          ) {
            if (typeof payload.redirect !== 'undefined' && !isLoggedIn.value) {
              await navigateTo(redirectWithParams(payload.route.path, payload.route.query)) // Redirect if not login page
              return
            }
            await navigateTo( `${affiliateCustomizationStore.bpBaseUrl}/login`, { external: true })
          }
          return {
            ok,
            status,
            statusText
          }
        } else {
          authUser.value = data.data
          const _affiliateId = get(data, 'data.marketingData.affiliateId')
          if (_affiliateId) {
            affiliateId.value = _affiliateId
          }
          await borrowerStore.getBorrower({ requestedBorrowerId: payload.borrowerId })
          this.getMarketingOwnership({ forceReload: true })
          experimentsStore.registerAllExperimentGroups($lendioCookies)
          return data.data
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        log.error('Error with getCurrentUser', error)
        return new Error('Error with getCurrentUser', error)
      } else {
        await this.destroyUser()
        await userStore.clearAccessToken()
        await navigateTo(`${affiliateCustomizationStore.bpBaseUrl}/login`, { external: true })
      }
    }
  }

  async function authenticateCurrentUser(payload = {}) {
    const _originalToken = authToken.value
    const _isEmbedded = isEmbedded.value
    const _authUser = authUser.value
    const embeddedOrLoggedIn = isLoggedIn.value || _isEmbedded

    if (!!_authUser && !payload.forceReload && embeddedOrLoggedIn) {
      return _authUser
    }
    try {
      const hadOriginalToken = !!_originalToken

      if (!hadOriginalToken || !embeddedOrLoggedIn) {
        return false
      }

      const { data, status, ok, statusText } = await $axios.get(
        `${env('bpApiUrl')}/user/currentUser`,
        {
          token: _originalToken,
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )

      if (!data.data || status !== 200) {
        // If the token is not the most recent token and the current token is not the basic token, this may be a late request and may not be needed
        if (authToken.value !== _originalToken && authUser.value) {
          return authUser.value
        }
        return false
      } else {
        authUser.value = data.data
        const _affiliateId = get(data, 'data.marketingData.affiliateId')
        if (_affiliateId) {
          affiliateId.value = _affiliateId
          affiliateStore.getAffiliate(affiliateId.value)
        }
        this.getMarketingOwnership({ forceReload: true })
        experimentsStore.registerAllExperimentGroups($lendioCookies)
        await borrowerStore.getBorrower()
        return authUser.value
      }
    } catch (error) {
      await this.destroyUser()
      userStore.clearAccessToken()
    }
  }

  /**
   * Make request for current borrower interest, saving current borrower interest to state
   * @returns {Promise.<void>}
   */
  async function postCurrentBorrowerInterest(interest = 'marketplace') {
    const borrower = await borrowerStore.getBorrower()
    const Authorization = userStore.getAuthHeader()
    if (!borrower || !borrower.id || !Authorization) {
      return
    }
    const { data } = await $axios.post(
      `${env('apiUrl')}/borrower/${borrower.id}/interest`,
      interest,
      {
        headers: { Authorization }
      }
    )
    const _currentBorrowerInterest = get(data, 'data', null)
    currentBorrowerInterest.value = _currentBorrowerInterest
  }

  async function getMarketingOwnership({ forceReload = false } = {}) {
    if (pwfSignupInProgress.value) return

    if (!forceReload && (marketingOwnership.value || validMarketingOwnershipResponse.value)) {
      return marketingOwnership.value
    }

    const borrower = await borrowerStore.getBorrower()
    const Authorization = userStore.getAuthHeader()

    if (!borrower || !Authorization) {
      return null
    }
    try {
      const { data } = await $axios.get(
        `${env('apiUrl')}/borrower/${borrower.id}/marketing/ownership`,
        { headers: { Authorization } }
      )

      if (get(data, 'statusCode', null) === 200) {
        validMarketingOwnershipResponse.value = true
      }

      const marketing = get(data, 'data', null)
      return this.setMarketingRecord(marketing)
    } catch (error) {
      log.error('Error during ${borrowerId}/marketing/ownership. ', error)
    }
  }

  function setMarketingRecord(marketing) {
    cobrandStore.setAffiliateCobrand(
      get(marketing, 'affiliateId')
    )

    marketingOwnership.value = marketing

    return marketing
  }

  async function routeForRenewals(payload = { redirect: null }) {
    await borrowerStore.getBorrower()
    await borrowerStore.getBorrowerValues()
    const thirtyDaysAgo = (new Date() - 24 * 60 * 60 * 1000 * 30) / 1000
    const dateAppSigned =
      new Date(borrowerStore.borrowerValues.date_signed.value).getTime() /
      1000
    const funded = borrowerStore.borrowerHasFundedDeals
    const appSignedWithinLastThirtyDays = dateAppSigned >= thirtyDaysAgo
    if (payload.redirect && !/renewal/i.exec(payload.redirect)) {
      router.push(payload.redirect)
    } else if (funded && appSignedWithinLastThirtyDays) {
      router.push('/portal')
    } else if (funded && !appSignedWithinLastThirtyDays) {
      router.push('/renewal')
    }
  }

  async function verificationFailedlogout({ redirect = `${env('baseUrl')}` }) {
    try {
      await $axios.post(`${env('bpApiUrl')}/user/logout`, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      this.destroyUser()
      userStore.clearAccessToken()
      await navigateTo(redirect, {
        external: true
      })
    } catch (e) {
      // If this 401s, we should still redirect the user.
      await navigateTo(redirect, {
        external: true
      })
    }
  }

  const logout = async function logout({
    path = '/',
  }) {
      await identityStore.logout(path)
      return;
  }

  async function embeddedLogout() {
    if (process.client) {
      localStorageService.clear()
    }
    if (authUser.value && authToken.value) {
      await $axios.post(`${env('bpApiUrl')}/user/logout`, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
    }
    this.destroyUser()
    userStore.clearAccessToken()
  }

  async function signInUser(query, errorHandler) {

    const {
      redirect,
      signupType = null,
      e: email,
      t: token,
      touchpoint,
      selfselect,
      tokenType,
    } = query

    if (signupType === 'pwf' || signupType === 'partnerApi' || signupType === 'partnerLeadForm') {
      this.loginTokenSignIn(query, errorHandler)
    } else {
      if (VisitorTracker.anyTrackedCookiesSet($lendioCookies)) {
        $lendioCookies.set('skipMarketingRecord', false)
      }

      const cookies = $lendioCookies.getTrackingCookies()

      // add cobrand cookie as backup if affiliate is null
      const cobrandAffiliateId = get(
        cobrandStore,
        'config.affiliateId',
        $lendioCookies.get('cobrand')
      )
      if (
        cobrandAffiliateId &&
        (!cookies.affiliate ||
          /^(null|undefined|NaN|0|false)$/i.exec(cookies.affiliate))
      ) {
        cookies.affiliate = cobrandAffiliateId
      }

      // workaround to fix url encoding replacing + with space
      const emailEncode = email.replace(/\s/g, '+')
      let tokenData = {
        username: emailEncode,
        password: token,
        touchpoint: touchpoint,
        cookies: JSON.stringify(cookies),
        skipMarketingRecord: $lendioCookies.get('skipMarketingRecord') === 'true'
      }

      if (selfselect) {
        tokenData.selfSelect = selfselect
      }

      const trackedCookies = VisitorTracker.trackedValues($lendioCookies)
      const trackedKeys = Object.keys(trackedCookies)
      const trackedValues = zipObject(
        trackedKeys,
        trackedKeys.map((key) => cookies[key] || trackedCookies[key])
      )

      Object.assign(tokenData, trackedValues)

      localStorageService.setItem('tokenData', JSON.stringify(tokenData))

      localStorageService.setItem('query', JSON.stringify(query))

      if (!checkUrlSafety(redirect)) {
        log.error('Sign-In navigation attempted to send to unsafe Url. Action terminated.')
        return navigateTo('/404');
      }

      await navigateTo(redirect, {
        external: true
      })
    }
  }

  async function loginTokenSignIn (query, errorHandler) {
    pwfSignupInProgress.value = true
    if (VisitorTracker.anyTrackedCookiesSet($lendioCookies)) {
      $lendioCookies.set('skipMarketingRecord', false)
    }

    const cookies = $lendioCookies.getTrackingCookies()

    // add cobrand cookie as backup if affiliate is null
    const cobrandAffiliateId = get(
      cobrandStore,
      'config.affiliateId',
      $lendioCookies.get('cobrand')
    )
    if (
      cobrandAffiliateId &&
      (!cookies.affiliate ||
        /^(null|undefined|NaN|0|false)$/i.exec(cookies.affiliate))
    ) {
      cookies.affiliate = cobrandAffiliateId
    }

    const {
      signupType = null,
      e: email,
      t: token,
      r: route,
      touchpoint,
      selfselect,
      tokenType,
    } = query

    // workaround to fix url encoding replacing + with space
    const emailEncode = email.replace(/\s/g, '+')
    let tokenData = {
      username: emailEncode,
      password: token,
      tokenType: tokenType,
      touchpoint: touchpoint,
      cookies: JSON.stringify(cookies),
      skipMarketingRecord: $lendioCookies.get('skipMarketingRecord') === 'true'
    }

    if (selfselect) {
      tokenData.selfSelect = selfselect
    }

    const trackedCookies = VisitorTracker.trackedValues($lendioCookies)
    const trackedKeys = Object.keys(trackedCookies)
    const trackedValues = zipObject(
      trackedKeys,
      trackedKeys.map((key) => cookies[key] || trackedCookies[key])
    )

    Object.assign(tokenData, trackedValues)

    localStorageService.setItem('tokenData', JSON.stringify(tokenData))

    localStorageService.setItem('query', JSON.stringify(query))

    this.postTokenLoginActions().catch((err) => {
      errorHandler(route)
    })
    localStorageService.setItem('pwfSignup', 'true')
  }

  async function postTokenLoginActions() {
    const query = JSON.parse(localStorageService.getItem('query'))
    const tokenData = JSON.parse(localStorageService.getItem('tokenData'))
    const {
      r: redirect,
      ref: referral,
      franchiseId,
      borrower: borrowerId,
      visitor: queryVisitorId,
      t: token,
      tokenType
    } = query
    const redirectURI = cleanUri(redirect)
    const passThroughParams = {
      ref: referral || franchiseId
    }

    let tokenFormData = new FormData()
    for (let key in tokenData) {
      tokenFormData.append(key, tokenData[key])
    }

    borrowerStore.setNewBorrowerId(borrowerId)
    this.setVisitorId(queryVisitorId)
    if (tokenType !== 'lendio_jwt')  {
      throw new Error(`Tried to perform PWF login, but tokenType was ${tokenType}`)
    }
    let _borrower = null
    try {
      let [
        tokenLoginResponse,
        experienceResponse,
        questionFiltersResponse,
        infoQuestionsResponse,
        ownershipResponse,
        filterQuestionsResponse] = await Promise.all(
        [
          identityStore.tokenLogin(token),
          experienceStore.forceGetExperience({ visitorId: queryVisitorId }),
          questionsStore.getQuestionFilters(),
          questionsStore.requestInfoQuestions(true),
          this.getMarketingOwnership(),
          questionsStore.requestFilterQuestions(null, true),
      ])
      _borrower = tokenLoginResponse?.borrower
    } catch (err) {
      throw new Error('auth token was unable to be set')
    }

    questionsStore.updateMlWhitelist()

    $lendioCookies.clearTrackingCookies()

    if (!authToken.value) {
      throw new Error('auth token was unable to be set')
    }

    await borrowerStore.getHasFundedDeals()

    // contactOption is determined by api borrower contactOption
    if (get(authUser.value, 'contactOption') === true) {
      $lendioCookies.set('contactOption', true, { expires: 7 })
    }

    if (get(authUser.value, 'lastTrack') !== false) {
      $lendioCookies.set('skipMarketingRecord', true, { expires: 7 })
    }
    TrackingService.onLogin(get(authUser.value, 'borrowerId'), $lendioCookies)

    // Clear search params
    router
      .replace({ query: { e: null, r: null, t: null } })
      .catch((err) => {}) //catch potential errors rather than spamming rollbar

    // add the tier param
    if (_borrower?.tier) {
      passThroughParams.tier = _borrower.tier
    }

    // add the mineral group
    const mineralGroup = get(
      authUser.value,
      'marketingData.mineralGroup',
      null
    )
    if (mineralGroup) {
      passThroughParams.mineral = mineralGroup.toLowerCase()
    }

    // redirectURI unless it's /bp/app. Otherwise use routing logic
    const renewal = borrowerStore.borrowerHasFundedDeals && redirect !== 'renewal/submit'
    if (renewal) {
      return this.routeForRenewals()
    }

    if (redirectURI && redirectURI !== '/app') {
      return router.push(redirectURI)
    }

    const nextRoute = BorrowerRouting.getNextRoute(borrower)
    return router.push({
      path: nextRoute,
      query: passThroughParams
    })
  }

  async function tokenLogin(formData, lendioJwt = null) {
    let options = {
      'Content-Type': 'application/x-www-form-urlencoded',
    }

    formData.append('tenantId', affiliateCustomizationStore.tenantId)
    formData.append('client_id', env('apiClientId'))

    const { data, status } = await $axios.post(
      `${env('apiUrl')}/authorization/token`,
      formData,
      options
    )

    if (status !== 200) {
      throw data
    }
    const { access_token, embeddedJwt: _embeddedJwt, intercomHash: _intercomHash } = data.data
    if (lendioJwt) {
      await this.setAuthToken(lendioJwt, 'LENDIO_JWT')
    } else {
      await this.setAuthToken(access_token)
    }

    this.setEmbeddedToken(_embeddedJwt)
    this.setIntercomHash(_intercomHash)

    // user token to get current user
    await this.getCurrentUser({ forceReload: true })
    return data.data
  }

  function setDynamicAppProgress(progress) {
    dynamicAppProgress.value = progress
    if (process.client) {
      localStorageService.setItem(
        'dynamicAppProgress',
        dynamicAppProgress.value
      )
    }
  }

  function setPWFSignupStatus(status) {
    pwfSignupInProgress.value = status
  }

  // Renewal App progress
  function setRenewalAppProgress(progress) {
    renewalAppProgress.value = progress
    if (process.client) {
      localStorageService.setItem(
        'renewalAppProgress',
        renewalAppProgress.value
      )
    }
  }

  function saveRenewalProgress(progress) {
    let progressObject = renewalAppProgress.value
    progressObject[progress.page] = progress.step
    this.setRenewalAppProgress(processObject)
  }

  function saveProgress(progress) {
    let progressObject = dynamicAppProgress.value
    progressObject[progress.page] = progress.step
    this.setDynamicAppProgress(progressObject)
  }

  function updateCurrentPage(_currentPage) {
    currentPage.value = _currentPage
  }

  async function requestPublicLenderList() {
    if (publicLenderList.value.length) return publicLenderList.value
    let res = await $axios
      .get(`${env('apiUrl')}/public/lender`)
      .catch((err) => {
        log.error('Error fetching stats', { err })
      })
    // Switch commented lenderList for local testing as needed
    const lenderList = PublicLenders.data
    // const lenderList = get(res, 'data.data', null) || []
    publicLenderList.value = lenderList
    return publicLenderList.value
  }

  function setPageTitle(string) {
    pageTitle.value = string
  }

  function setLendioFavicons() {
    let faviconPath = 'images/'
    if (affiliateCustomizationStore.useCustomFavicon && affiliateCustomizationStore.key) {
      faviconPath = `https://cdn.lendio.com/tenant/${affiliateCustomizationStore.key}/`
    }

    favicons.value = [
      {
        rel: 'icon',
        type: 'image/png',
        href: faviconPath + 'favicon-32x32.png'
      },
      {
        rel: 'icon',
        type: 'image/png',
        href: faviconPath + 'favicon-16x16.png'
      },
      {
        rel: 'mask-icon',
        href: faviconPath + 'safari-pinned-tab.svg',
        color: DEFAULT_THEME_COLOR
      },
      {
        rel: 'shortcut icon',
        href: faviconPath + 'favicon.ico'
      },
      {
        rel: 'icon',
        href: faviconPath + 'favicon.ico',
        type: 'image/x-icon'
      }
    ]
  }

  function setBlankFavicons() {
    favicons.value = [
      {
        rel: 'shortcut icon',
        href: env('themeFavicon') || 'images/blank-favicon.ico',
        type: 'image/x-icon'
      }
    ]
  }

  function setAffiliateId(_affiliateId) {
    affiliateId.value = _affiliateId
  }

  function setHasSetPassword(value = true) {
    if (authUser.value) {
      authUser.value.has_set_password = value
    }
  }

  // TODO: Move to getter and remove JWT check once Bearer strategy not supported. Should replace uses of state value 'authTokenExpiry'
  function getAuthTokenExpiry() {
    const _authToken = authToken.value
    return  $lendioCookies.get('authType') === 'LENDIO_JWT' && _authToken ? JSON.parse(atob(_authToken.split('.')[1])).exp : null
  }


  return {
    // STATE
    activationId,
    anonymousId,
    authUser,
    authToken,
    authTokenExpiry,
    expiryTimeoutSet,
    displayExpiryModal,
    affiliateId,
    isEmbedded,
    embeddedJwt,
    intercomHash,
    facebookAuth,
    pageTitle,
    favicons,
    dynamicAppProgress,
    renewalAppProgress,
    currentPage,
    marketingOwnership,
    currentBorrowerInterest,
    publicLenderList,
    useLegacyDesign,
    tenantId,
    testing,
    pwfSignupInProgress,
    visitorId,
    isCypressUser,

    // GETTERS
    getEmbeddedInitBody,
    getPublicLenderList,
    getAffiliateId,
    naicsMarketplace,
    userProfile,
    canUseEmbedded,
    isLoggedIn,

    // ACTIONS
    setAuthToken,
    setAnonymousId,
    setIsEmbedded,
    setBorrowerId,
    setEmbeddedToken,
    setIntercomHash,
    destroyUser,
    destroyCookiesAndLocalStorage,
    getIntercomHash,
    getTokenExpiration,
    nuxtServerInit,
    authenticate,
    getActivationIdFromStorage,
    getCurrentUser,
    authenticateCurrentUser,
    postCurrentBorrowerInterest,
    getMarketingOwnership,
    routeForRenewals,
    setTenantIdFromRoute,
    verificationFailedlogout,
    logout,
    embeddedLogout,
    signInUser,
    loginTokenSignIn,
    postTokenLoginActions,
    tokenLogin,
    setDynamicAppProgress,
    setRenewalAppProgress,
    setVisitorId,
    saveRenewalProgress,
    saveProgress,
    trimStringQuotes,
    updateCurrentPage,
    requestPublicLenderList,
    setPageTitle,
    setLendioFavicons,
    setMarketingRecord,
    setBlankFavicons,
    setAffiliateId,
    setHasSetPassword,
    setPWFSignupStatus,
    setupTokenExpirationTimeout,
    setDisplayExpiryModal,
    getAuthTokenExpiry
  }
})
