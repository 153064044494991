import { format } from 'date-fns'

/**
 * List of English articles
 * @type {array}
 */
export const articles = ['a', 'an', 'the']

/**
 * List of English preposition
 * @type {array}
 */
export const prepositions = ['aboard', 'about', 'above', 'absent', 'across', 'cross', 'after', 'against', '\'gainst', 'gainst', 'again', 'gain', 'along', '\'long', 'alongst', 'alongside', 'amid', 'amidst', 'mid', 'midst', 'among', 'amongst', '\'mong', 'mong', '\'mongst', 'apropos', 'apud', 'around', '\'round', 'round', 'as', 'astride', 'at', 'on', 'atop', 'ontop', 'bar', 'before', 'afore', 'tofore', 'behind', 'ahind', 'below', 'ablow', 'allow', 'beneath', '\'neath', 'neath', 'beside', 'besides', 'between', 'atween', 'beyond', 'ayond', 'but', 'by', 'chez', 'circa', 'c.', 'ca.', 'come', 'dehors', 'despite', 'spite', 'down', 'during', 'except', 'for', 'from', 'in', 'inside', 'into', 'less', 'like', 'minus', 'near', 'nearer', 'nearest', 'anear', 'notwithstanding', 'of', 'o\'', 'off', 'on', 'onto', 'opposite', 'out', 'outen', 'outside', 'over', 'o\'er', 'pace', 'past', 'per', 'post', 'pre', 'pro', 'qua', 're', 'sans', 'save', 'sauf', 'short', 'since', 'sithence', 'than', 'through', 'thru', 'throughout', 'thruout', 'to', 'toward', 'towards', 'under', 'underneath', 'unlike', 'until', '\'til', 'til', 'till', 'up', 'upon', '\'pon', 'pon', 'upside', 'versus', 'vs.', 'v.', 'via', 'vice', 'vis-à-vis', 'with', 'w/', 'c̄', 'within', 'w/i', 'without', 'w/o', 'worth']

/**
 * Format a bool borrower attribute
 * @param  {string|number} value    The value to format
 * @param  {mixed} fallback The value to return on error
 * @return {string}          The formatted value
 */
export function bool (value, fallback = '') {
  if (value === '0' || value === 0) return 'No'
  if (value) return 'Yes'
  return fallback
}

/**
 * Format a currency value
 * @param  {string|number}  value    The value to format
 * @param  {boolean} decimal  Whether the value is a decimal or not
 * @param  {mixed}  fallback The value to return on error
 * @return {string}           The formatted string
 */
export function currency (value, decimal = false, fallback = '') {
  const formatted = number(value, decimal, fallback)
  if (formatted === fallback) return fallback
  return `$${formatted}`
}

/**
 * Format a date value
 * @param  {string} value    The value to format
 * @param  {mixed} fallback The value to return on error
 * @return {string}          The formatted string
 */
export function dateInput (value, fallback = '') {
  if (typeof value !== 'string') return fallback
  const parts = value.split('-')
  if (parts.length !== 3) return value
  const [ year, month, day ] = parts
  const date = new Date(year, parseInt(month, 10) - 1, day)
  return format(date, 'MMM d, yyyy')
}

/**
 * Format a date value with only month and year
 * @param  {string} value    The value to format
 * @param  {mixed} fallback The value to return on error
 * @return {string}          The formatted string
 */
export function dateOnlyMonthYearInput (value, fallback = '') {
  if (typeof value !== 'string') return fallback
  const dateAndTime = value.split(' ')
  if (dateAndTime.length === 0) return value
  const dateString = dateAndTime[0]
  const parts = dateString.split('T')[0].split('-')
  if (parts.length !== 3) return value
  const [ year, month, day ] = parts
  const date = new Date(year, parseInt(month, 10) - 1, day)
  return format(date, 'MMM yyyy')
}

/**
 * Format multi-select borrower attributes
 * @param  {string} value    The string to format
 * @param  {mixed} fallback The value to return on error
 * @return {string}          The formatted string
 */
export function multiselect (value, fallback = '') {
  if (typeof value !== 'string') return fallback

  return oxfordComma(
    value.split(',')
      .map(splitCamel)
      .map(titleCase)
      .map(group => group.join(' '))
  )
}

/**
 * Format a number
 * @param  {string|number}  value    The value to format
 * @param  {boolean} decimal  Whether the value is a decimal or not
 * @param  {mixed}  fallback The value to return on error
 * @return {string}           The formatted value
 */
export function number (value, decimal = false, fallback = '') {
  if (typeof value !== 'string' && typeof value !== 'number') return fallback

  const operand = decimal ? parseFloat : parseInt
  let parsed = operand(value.toString().replace(/[^\d.]/g, ''))
  if (isNaN(parsed)) return fallback

  if (decimal) parsed = parsed.toFixed(2)

  return parsed.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
}

/**
 * Return an unformatted number, useful for ensuring non-numeric values are removed
 * @param  {string|number}  value    The value to format
 * @param  {boolean} decimal  Whether the value is a decimal or not
 * @param  {mixed}  fallback The value to return on error
 * @return {string}           The formatted value
 */
export function unformattedNumber (value, decimal = false, fallback = '') {
  return number(value, decimal, fallback).replace(/,/g, '')
}

/**
 * @typedef {Object} AbbreviationMapEntry
 * @property { number } AbbreviationMapEntry.magnitude The minimum magnitude this suffix can abbreviate
 * @property { number } AbbreviationMapEntry.trailingDecimals The number of trailing decimals to include
 * @typedef { Record<string, number } SimpleAbbreviationMap A map where the key is the suffix to use and the value is the minimum magnitude this suffix can abbreviate
 * @typedef { Record<string, AbbreviationMapEntry} AbbreviationMapWithTrailingDecimal A map where the key is the suffix and the value is a map containing the minimum magnitude the suffix can abbreviate
 * and the number of trailing decimals to use with this suffix
 *
 * Abbreviates a number and appends a suffix.
 * @param { number } value The number to abbreviate
 * @param { number } [trailingDecimals = 0] The number of decimal places to preserve in the abbreviation.  Default is 0
 * @param { SimpleAbbreviationMap | AbbreviationMapWithTrailingDecimal } [abbreviationMap] A well-ordered map of abbreviations and the minimum magnitude they can abbreviate.
 * This will replace the default provided map of K, M, B, T.
 * It should be ordered by magnitude
 * @returns {string} The abbreviated value
 */
export function abbreviatedNumber(
  value,
  trailingDecimals = 0,
  abbreviationMap
) {
  const magnitude = Math.floor(Math.log10(value))
  let suffix = ''
  let logPower = 0
  abbreviationMap = abbreviationMap ?? {
    K: 3,
    M: 6,
    B: 9,
    T: 12
  }
  for (const key in abbreviationMap) {
    const mapValue = abbreviationMap[key]
    if (mapValue instanceof Object) {
      if (mapValue.magnitude > magnitude) break
      suffix = key
      logPower = mapValue.magnitude
      trailingDecimals = mapValue.trailingDecimals
    } else {
      if (mapValue > magnitude) break
      suffix = key
      logPower = mapValue
    }
  }
  return `${Number(
    (value / 10 ** logPower).toFixed(trailingDecimals)
  )}${suffix}`
}

export function address({ street, city, state, zip }) {
  if (!street && !city && !state && !zip) {
    return ''
  }

  street = street || ''
  city = city || ''
  state = state || ''
  zip = zip || ''

  return `${street},\n${city} ${state} ${zip}`
}

/**
 * Format a list with the Oxford comma style
 * @param  {array} values   List of strings
 * @param  {mixed} fallback The value to return on error
 * @return {string}          The formatted string
 */
export function oxfordComma (values, fallback = '') {
  if (!Array.isArray(values) || values.length === 0) return fallback
  if (values.length === 1) return values[0]
  if (values.length === 2) return `${values[0]} and ${values[1]}`
  return `${values.slice(0, values.length - 1).join(', ')}, and ${values[values.length - 1]}`
}

/**
 * Format percent values
 * @param  {string|number} value    The value to format
 * @param  {mixed} fallback The value to return on error
 * @return {string}          The formatted value
 */
export function percent (value, fallback = '') {
  if (typeof value !== 'string' && typeof value !== 'number') return fallback
  return `${value}%`
}

/**
 * Format percent of sales values
 * @param  {string|number} value    The value to format
 * @param  {mixed} fallback The value to return on error
 * @return {string}          The formatted value
 */
export function percentOfSales (value, fallback = '') {
  if (typeof value !== 'string' && typeof value !== 'number') return fallback
  return `${value}% of Sales`
}

/**
 * Format a US phone number
 * @param  {string|number} value    The phone number to format
 * @param  {mixed} fallback The value to return on error
 * @return {string}          The formatted phone number
 */
export function phone (value, fallback = '') {
  const typeofValue = typeof value;
  if (typeofValue !== 'string' && typeofValue !== 'number') return fallback
  // This should be fine since no US area codes start with zero
  if (typeofValue === 'number' && Math.trunc(Math.log10(value)) !== 9) return fallback
  const strValue = value.toString()
  const nbsp = '\u00A0'
  const nbHyphen = '\u2011'
  /*
  This regex says 
  0. Starting anywhere in the given string, find single match by
  1. Capturing three consecutive digits into the first group. 
  2. Then allowing zero or more non-digits
  3. Capturing three consecutive digits into the second group
  4. Again, allowing zero or more non-digits
  5. And finally, capturing four consecutive digits into the third group
  6. After finding a single match, stop matching and return.
   */
  const regex = /^.*(?:\+1)?.*(\d{3}).*(\d{3}).*(\d{4})/
  const match = regex.exec(strValue)
  if (!match) return fallback
  // Index zero is the matched string. We just want the match groups, so start at index 1
  const matchGroup1 = match[1]
  const matchGroup2 = match[2]
  const matchGroup3 = match[3]
  return `(${matchGroup1})${nbsp}${matchGroup2}${nbHyphen}${matchGroup3}`
}

/**
 * Format select borrower attributes
 * @param  {string} value    The value to format
 * @param  {mixed} fallback The value to return on error
 * @return {string}          The formatted string
 */
export function select (value, fallback = '') {
  if (typeof value !== 'string') return fallback
  return titleCase(value).join(' ')
}

/**
 * Format select borrower attribute value for display
 * @param  {string} value    The value to format
 * @param  {mixed} fallback The value to return on error
 * @return {string}          The formatted string
 */
export function formatSelectValueForDisplay (value, fallback = '') {
  if (typeof value !== 'string') return fallback
  return titleCase(value).join(' ').split(/(?=[A-Z])/g).join(' ')
}

/**
 * Split a camelCase string
 * @param  {string} value The value to format
 * @return {array}       List of split parts
 */
export function splitCamel (value) {
  if (typeof value !== 'string') return null
  return value.split(/(?=[A-Z])/g)
}

/**
 * Format a SSN for obscurity
 * @param  {string|number} value    The SSN
 * @param  {mixed} fallback The value to return on error
 * @return {string}          The formatted string
 */
export function ssn (value, fallback = '') {
  if (typeof value !== 'string' && typeof value !== 'number') return fallback
  value = value.toString()
  if (value.length !== 9) return value
  return value.replace(/\d{5}(\d{4})/, (match, p1) => `** *** ${p1}`)
}

/**
 * Format a tax ID
 * @param  {string|number} value    The tax ID
 * @param  {mixed} fallback The value to return on error
 * @return {string}          The formatted string
 */
export function taxId (value, fallback = '') {
  if (typeof value !== 'string' && typeof value !== 'number') return fallback
  value = value.toString()
  if (value.length !== 9) return value
  return value.replace(/(\d{2})(\d{7})/, (match, p1, p2) => `${p1}-${p2}`)
}

/**
 * Convert a string or list to Title Case
 * @param  {string|array} value    The value to format
 * @param  {mixed}  fallback The value to return on error
 * @return {array}          List of formatted strings
 */
export function titleCase (value, fallback = []) {
  if (typeof value === 'string') value = value.trim().split(/\s+/g)
  if (!Array.isArray(value)) return fallback
  const lowercase = prepositions.concat(articles)
  let phrase = value.map(word => {
    return lowercase.indexOf(word.toLowerCase()) === -1
      ? upperCaseFirst(word)
      : word.toLowerCase()
  })
  if (phrase.length) phrase[0] = upperCaseFirst(phrase[0])
  return phrase
}

export function capitalizeFirstChar(string) {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function upperCaseFirst (value) {
  return value.replace(/(.)(.*)/g, (match, p1, p2) => `${p1.toUpperCase()}${p2}`)
}

export default {
  articles,
  prepositions,
  address,
  bool,
  currency,
  dateInput,
  dateOnlyMonthYearInput,
  multiselect,
  number,
  unformattedNumber,
  abbreviatedNumber,
  oxfordComma,
  percent,
  phone,
  select,
  splitCamel,
  ssn,
  taxId,
  titleCase,
}
