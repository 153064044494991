import { defineStore } from 'pinia'

import { ApplicationConfigService } from '~/libs/store-services/application-config-service'
import appNameFromRoute from '~/libs/app-name-from-route'

import { useBorrowerStore } from '~/store/borrower'
import { useRootStore } from '~/store/root'
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'
import { useExperimentsStore } from '~/store/experiments'
import { useAppAnalyticsStore } from '~/store/app-analytics'
import { MBOX_DETAILS } from '~/libs/adobe-target'
import env from '../libs/env'

export const useApplicationConfigStore = defineStore('applicationConfig', () => {
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp
  const route = useRoute()
  const applicationConfigService = new ApplicationConfigService($axios)
  const borrowerStore = useBorrowerStore()
  const rootStore = useRootStore()
  const affiliateCustomizationStore = useAffiliateCustomizationStore()
  const experimentsStore = useExperimentsStore()
  const appAnalyticsStore = useAppAnalyticsStore()

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
  */
  const applicationConfig = ref(null)
  const documentUploadRequestApplicationConfig = ref(null)
  const stale = ref(true)
  const preloadedPages = ref([])
  const preloadedIdentifier = ref('')
  const basicInfoModuleTestEnabled = ref(false)
  const isBackEvent = ref(false)
  const appConfigNavEnabled = ref(false)
  const completedModuleEventPaths = ref([])

  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
  */

  const appIdentifierRoute = computed(() => {
    return `${affiliateCustomizationStore.preapprovalOnly ? 'preapproval' : 'marketplace'}-app`
  })

  const navItems = computed(() => {
    return applicationConfig.value?.navigation ?? [];
  })

  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
  */

  async function setIsBackEvent(isBack = false) {
    isBackEvent.value = isBack
  }

  async function getByIdentifierAndSlug({ identifier, slug, direction }) {

    // return previously loaded value if we have it
    if (preloadedIdentifier.value !== identifier) {
      preloadedPages.value = []
    }

    if (!stale.value && !direction && this.checkSlugPreloaded(slug)) {
      return applicationConfig.value
    }

    const borrower = await borrowerStore.getBorrower()
    const borrowerId = borrower?.id
    const tenantId = affiliateCustomizationStore.tenantId

    const {appConfigIdentifier} = route.query

    if (appConfigIdentifier && borrower?.isTest === 1) {
      identifier = appConfigIdentifier
    }

    const payload = {
      anonymousId: rootStore.anonymousId,
      identifier,
      borrowerId,
      tenantId,
      direction,
      slug,
    }
    const { data: _applicationConfig, error } = await applicationConfigService.getByIdentifierAndSlug(payload)

    if (error) {
      let errorStatus
      let errorMsg = 'Failed to fetch Application Config.'
      switch (error.status) {
        case 403:
          errorStatus = 403,
          errorMsg = `${errorMsg} Access denied: /${identifier}-app`
          break
        case 404:
          errorStatus = 404,
          errorMsg = `${errorMsg} Page not found: /${identifier}-app`
          break
        default:
          errorStatus = 500
          errorMsg = `${errorMsg} ${error.message}`
      }
      throw createError({
        statusCode: errorStatus,
        statusMessage: errorMsg,
        fatal: true
      })
    }

    if (!borrowerId) {
      // In an unauth'd scenario, if we're using localStorage inject answers if we have no borrowerId
    }

    // update applicationConfig state
    applicationConfig.value = _applicationConfig
    stale.value = false

    preloadedIdentifier.value = identifier
    preloadedPages.value = _applicationConfig.modules.reduce((pageSlugs, module) => {
      if (module.includesData) {
        pageSlugs.push(module.urlSlug)
      }
      return pageSlugs
    }, [])


    // return config
    return applicationConfig.value
  }

  async function getDocumentUploadRequestApplicationConfig(documentUploadRequestInviteToken = null) {
    const borrowerId = borrowerStore.borrower?.id;

    const response = await $axios.get(
      borrowerId
        ? `${env('apiUrl')}/application-config/borrower/${borrowerId}/document-upload-request`
        : `${env('apiUrl')}/application-config/guest/document-upload-request`,
      {
        headers: !borrowerId && documentUploadRequestInviteToken ? {
          Authorization: `lendio-jwt ${documentUploadRequestInviteToken}`,
        } : null,
      }
    )

    const configFromResponse = response?.data?.data ?? null

    if (configFromResponse) {
      configFromResponse.modules = configFromResponse?.modules?.map((module) => ({ ...module, completed: documentModuleComplete(module)}));
    }

    documentUploadRequestApplicationConfig.value = configFromResponse
    return documentUploadRequestApplicationConfig.value
  }

  function documentModuleComplete(module) {
    return module.moduleDocuments.every(moduleDocument => moduleDocument.requirements.every(req => req.document))
  }

  async function postApplicationEvent({ identifier, slug, eventType, path = null }) {
    if (!identifier || !eventType) {
      return false
    }

    if (eventType === 'moduleCompleted' && path && !checkPathForCompletedEvent(path)) {
      completedModuleEventPaths.value.push(path)
    }

    const borrower = await borrowerStore.getBorrower()
    const borrowerId = borrower?.id

    const payload = {
      identifier,
      slug,
      eventType,
      anonymousId: rootStore.anonymousId,
      borrowerId
    }
    const { error } = await applicationConfigService.postApplicationEvent(payload)

    if (error) {
      log.warning('Failed to save application event', { message: error.message, eventType })
    }

    // Return boolean on success. No error = success
    return !error
  }

  function loadAnswersFromStorage() {
    if (borrowerStore.borrower || borrowerStore.borrowerId) {
      return {}
    }
    return applicationConfigService.loadAnswersFromStorage()
  }

  async function saveAnswers({ answers: _answers = null } = {}) {
    if (!Object.keys(_answers).length) {
      return
    }

    if (!borrowerStore.borrower || !borrowerStore.borrowerId) {
      const data = _answers
      applicationConfigService.mergeAnswersToStorage(data)

      return Object.keys(_answers)
    }

    const data = await applicationConfigService.saveAnswers({
      authenticated: Boolean(rootStore.authUser),
      answers: _answers,
      borrowerId: borrowerStore.borrowerId
    })
    stale.value = true

    if (data) {
      return Object.keys(_answers)
    }

    return []
  }

  async function updateBasicInfoModuleTest() {
    if (basicInfoModuleTestEnabled.value) {
      return
    }

    const appName = appNameFromRoute(route)

    if (!appName || appName !== 'marketplace' || !affiliateCustomizationStore.isLendioTenant) {
      basicInfoModuleTestEnabled.value = false
      return
    }

    if (import.meta.server) {
      return // don't run check target on server
    }

    const targetRes = await appAnalyticsStore.checkSingleTargetOffer({
      mbox: MBOX_DETAILS.BASIC_INFO_MODULE.NAME,
      searchTerm: 'basicInfoModule',
      params: {
        testKeyword: route.query?.test,
      },
    })

    if (targetRes) {
      basicInfoModuleTestEnabled.value = true
    }
  }

  async function updateAppConfigNavTest() {
    if (appConfigNavEnabled.value) {
      return
    }

    const appName = appNameFromRoute(route)

    if (!appName || appName !== 'marketplace' || !affiliateCustomizationStore.isLendioTenant) {
      return
    }

    if (import.meta.server) {
      return // don't run check target on server
    }

    const borrowerCreated = borrowerStore?.borrower?.created

    if (borrowerCreated){
      const borrowerCreatedDate = new Date(borrowerCreated)
      const dec1st = new Date('2024-12-01')
      const shouldEnableNavTest =  borrowerCreatedDate > dec1st

      if (!shouldEnableNavTest) {
        return
      }
    }

    const targetRes = await appAnalyticsStore.checkSingleTargetOffer({
      mbox: MBOX_DETAILS.APP_CONFIG_NAV.NAME,
      searchTerm: 'appConfigNav',
      params: {
        testKeyword: route.query?.test,
      },
    })

    if (targetRes) {
      appAnalyticsStore.fsTrackEvent({ name: 'App Config Nav Test' })
      appConfigNavEnabled.value = true
    }
  }


  async function _updateExperimentEnabledStoreVar({experimentState, experimentName, allowedAppNames = [], testGroup = 1} = {}) {
    if (!experimentState || experimentState?.value === undefined || !experimentName || !allowedAppNames.length) {
      return
    }

    const appName = appNameFromRoute(route)
    if (!appName || !allowedAppNames.includes(appName)) {
      experimentState.value = false
      return
    }

    const user = rootStore.authUser
    if (!user) {
      experimentState.value = false
      return
    }

    await experimentsStore.fetchActiveExperimentsForCurrentUser()
    const isTestActive = experimentsStore.activeUserExperiments
      .some((userExperiment) => userExperiment.name === experimentName && userExperiment.group === testGroup)

    if (isTestActive) {
      experimentState.value = true
    }
  }

  function checkSlugPreloaded(slug = null) {
    if (!slug) {
      return false
    }
    return preloadedPages.value.includes(slug)
  }

  function checkPathForCompletedEvent(path = null) {
    if (!path) {
      return false
    }
    return completedModuleEventPaths.value.includes(path)
  }

  function getModuleFromSlug(slug) {
    let slugModule
    const _modules = applicationConfig.value?.modules
    if (_modules && _modules.length && slug) {
     slugModule = _modules.find((module) => {
      return module.urlSlug === slug
     })
    }
    return slugModule ?? null
  }

  return {
    // STATE
    appConfigNavEnabled,
    applicationConfig,
    basicInfoModuleTestEnabled,
    completedModuleEventPaths,
    documentUploadRequestApplicationConfig,
    isBackEvent,
    navItems,
    preloadedIdentifier,
    preloadedPages,
    stale,

    // GETTERS
    appIdentifierRoute,

    // ACTIONS
    checkSlugPreloaded,
    checkPathForCompletedEvent,
    getByIdentifierAndSlug,
    getDocumentUploadRequestApplicationConfig,
    getModuleFromSlug,
    loadAnswersFromStorage,
    postApplicationEvent,
    saveAnswers,
    setIsBackEvent,
    updateAppConfigNavTest,
    updateBasicInfoModuleTest,
  }
})
