import { defineStore } from "pinia";
import env from "~/libs/env";
import Pusher from "pusher-js";

import get from "lodash/get";
import any from "lodash/some.js";

import { differenceInMonths, differenceInDays, parseISO as dateParseISO } from "date-fns";
import SignupBorrowerService from "~/libs/signup-borrower-service";
import UserService from "~/libs/user-service";

import { MBOX_DETAILS } from "~/libs/adobe-target";
import { lsUtils } from '~/libs/ls-utils-service'
import { getMineral, mineralTypes } from '~/libs/mineral-calculation'

import { useAffiliateCustomizationStore } from "./affiliate-customization";
import { useAppAnalyticsStore } from "~/store/app-analytics";
import { useDealsStore } from "~/store/deals";
import { useDocumentsStore } from "~/store/documents";
import { useProgressStore } from "~/store/progress";
import { useQuestionsStore } from "~/store/questions";
import { useRootStore } from "~/store/root";
import { useUserStore } from "~/store/user";

export const useBorrowerStore = defineStore("borrower", () => {
  const nuxtApp = useNuxtApp();
  const router = useRouter();
  const { $axios, $lendioCookies } = nuxtApp;

  const affiliateCustomizationStore = useAffiliateCustomizationStore();
  const appAnalyticsStore = useAppAnalyticsStore();
  const dealsStore = useDealsStore();
  const documentsStore = useDocumentsStore();
  const progressStore = useProgressStore();
  const questionsStore = useQuestionsStore();
  const rootStore = useRootStore();
  const userStore = useUserStore();

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const additionalBorrowersUnsavedAnswers = ref({});
  const additionalBorrowersWithContacts = ref(null);
  const affiliatedBusinesses = ref({});
  const appRequirements = ref(null);
  const applicationRequirements = ref({});
  const appRequirementsLoaded = ref(false);
  const avgMonthlySalesSource = ref(null);
  const b2bContactData = ref([]);
  const b2bContactModal = ref(false);
  const b2bEditData = ref([]);
  const b2bEditModal = ref(false);
  const borrower = ref(null);
  const borrowerApplicationProgress = ref(null);
  const borrowerBusinessContacts = ref(null);
  const borrowerExperiments = ref([]);
  const borrowerExperimentsDetailed = ref([]);
  const borrowerHasFundedDeals = ref(null);
  const borrowerId = ref(null);
  const borrowerInsights = ref(null);
  const isBorrowerInsightsLoaded = ref(false);
  const borrowerLastDeal = ref(null);
  const borrowerLastStatus = ref(null);
  const borrowerValues = ref(null);
  const businessId = ref(null);
  const businessListing = ref(null);
  const creditPullStatus = ref(null);
  const legalTexts = ref({});
  const finicityMonthsBack = ref(3);
  const hasSunriseAccount = ref(false);
  const legallyRequiredMonthsBack = ref(3);
  const lenderUser = ref(null);
  const lenderUserLoaded = ref(false);
  const lenderUserOnline = ref(false);
  const returningUserParams = ref({});
  const signatureExpired = ref(false);
  const esignatureAttempted = ref(false);
  const signupBorrower = ref(null);
  const submitting = ref(false);
  const estimatedRenewalEligibility = ref(null);
  const legalTextTypes = ref(['signup_agreement', 'esignature_header', 'esignature'])

  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/

  const legalTextsLoaded = computed(() => {
    return Boolean(Object.keys(legalTexts.value).length === legalTextTypes.value.length)
  });

  const showLegalModal = computed(() => {
    return Boolean(signatureExpired.value && !esignatureAttempted.value)
  });

  const isEmbeddedEligible = computed(() => {
    const _borrower = borrower.value;
    return (
      _borrower !== null &&
      _borrower.name !== null &&
      _borrower.street !== null &&
      _borrower.city !== null &&
      _borrower.stateId !== null &&
      _borrower.zipId !== null
    );
  });

  const getMonthsInBusiness = computed(() => {
    const startDate = get(borrowerValues.value, "business_start_date.value");
    if (startDate) {
      return differenceInMonths(new Date(), new Date(startDate));
    }
    return null;
  })

  const getViewedEligibilityExpectations = computed(() => {
    const viewedEligibilityExpectations = get(borrowerValues.value, "viewedEligibilityExpectations.value");
    if (viewedEligibilityExpectations) {
      return viewedEligibilityExpectations;
    }
    return false;
  })

  const debtScheduleComplete = computed(() => {
    return (
      hasBusinessDebt.value === false
      || documentsStore.hasOfTypes([{ type: 'debtSchedule' }])
    )
  })

  /**
   * Returns value of hasBusinessDebt borrowerValue, as a boolean, or null if borrowerValue doesn't exist yet
   * @returns true|false|null
   */
  const hasBusinessDebt = computed(() => {
    if (!borrowerValues.value) {
      return null
    }

    const hasBusinessDebtBV = get(borrowerValues.value, 'hasBusinessDebt.value')

    if (hasBusinessDebtBV === null) {
      return null
    }

    return hasBusinessDebtBV === 1 || hasBusinessDebtBV === "1" || hasBusinessDebtBV === true
  })

  const getEmbeddedOrganization = computed(() => {
    const user = rootStore.authUser;
    let zipcode = null;

    if (!user || !isEmbeddedEligible.value) {
      return null;
    }

    const monthsInBusiness = getMonthsInBusiness.value;

    if (borrower.value.zipId) {
      zipcode =
        typeof borrower.value.zipId === "string"
          ? Number(borrower.value.zipId)
          : borrower.value.zipId;
    }

    const fetchQuestionValue = (alias, category) => {
      const question = questionsStore[category].find((q) => q.alias === alias);

      return question ? question.value : null;
    };

    const taxId = fetchQuestionValue(
      "federalstate_tax_id",
      "businessInfoQuestions"
    );
    const ssn = fetchQuestionValue("contact.ssn", "ownerCreditQuestions");
    const ownerBirthDate = fetchQuestionValue(
      "ownerBirthDate",
      "ownerCreditQuestions"
    );

    return {
      id: borrower.value.id,
      name: borrower.value.name,
      street: borrower.value.street,
      city: borrower.value.city,
      state: borrower.value.stateId,
      zipId: zipcode,
      ownerStreetAddress: get(borrowerValues.value, "owner_street_address.value"),
      ownerCity: get(borrowerValues.value, "owner_city.value"),
      ownerState: get(borrowerValues.value, "owner_state.value"),
      ownerZip: get(borrowerValues.value, "owner_zip.value"),
      timeInBusiness: monthsInBusiness,
      phone: borrower.value.phone,
      averageMonthlySales: get(
        borrowerValues.value,
        "average_monthly_sales.value"
      ),
      industry: get(borrowerValues.value, "lendioIndustry.value"),
      entityType: get(borrowerValues.value, "entity_type.value"),
      percentOwnership: get(borrowerValues.value, "percentOwnership.value"),
      creditScore: get(borrowerValues.value, "creditScore.value"),
      annualPersonalIncome: get(
        borrowerValues.value,
        "annual_personal_income.value"
      ),
      financeAmount: get(borrowerValues.value, "financeAmount.value"),
      loanPurpose: get(borrowerValues.value, "loanPurpose.value"),
      federalStateTaxId:
        get(borrowerValues.value, "federalstate_tax_id.value") || taxId,
      nonprofit: get(borrowerValues.value, "nonprofit.value"),
      bankruptcy: get(borrowerValues.value, "bankruptcy.value"),
      bankruptcyStatus: get(borrowerValues.value, "bankruptcy_status.value"),
      bankruptcyDischargedDate: get(
        borrowerValues.value,
        "bankruptcy_discharged_date.value"
      ),
      ownerBirthDate:
        get(borrowerValues.value, "ownerBirthDate.value") || ownerBirthDate,
      contact: {
        ssn,
      },
    };
  });

  const getAppRequirements = computed(() => {
    return () => {
      return applicationRequirements.value
    };
  });

  const getBorrowerId = computed(() => {
    //if simple type (int, bool), declare as const here then use const in return..
    return get(borrower, 'value.id');
  });

  const getBorrowerUserId = computed(() => {
    return get(borrower, 'value.userId');
  });

  const lenderUserAvatarUrl = computed(() => {
    const id = get(lenderUser.value.id, "");
    const url = env("avatarBaseUrl");
    return `${url}/${id}.png`;
  });

  const marketplaceCBI = computed(() => {
    const cbi = get(borrowerValues.value, "currentBorrowerInterest.value");
    return cbi === "marketplace";
  });

  const mpApplicationComplete = computed(() => {
    const appComplete = get(borrower, 'value.applicationComplete');
    return appComplete;
  });

  const getLegalTextBySlug = computed(() => (slug) => {
    if (!affiliateCustomizationStore.isLendioTenant) {
      if (slug === 'esignature_header') {
        return 'Welcome back!'
      }
      if (affiliateCustomizationStore.partnerTerms) {
        if (slug === 'signup_agreement' && !rootStore.isEmbedded) {
          return affiliateCustomizationStore.partnerTerms
        }
        if (slug === 'esignature') {
          return affiliateCustomizationStore.getPartnerConsentTerms('esignature')
        }
      }
    }
    return legalTexts.value[slug] ?? ''
  })

  const experianCreditRanges = computed(() => {
    return [
      {
        grade: 'Exceptional',
        min: 800,
        max: 850
      },
      {
        grade: 'Very good',
        min: 740,
        max: 799
      },
      {
        grade: 'Good',
        min: 670,
        max: 739
      },
      {
        grade: 'Fair',
        min: 580,
        max: 669
      },
      {
        grade: 'Poor',
        min: 300,
        max: 579
      }
    ]
  });

  const isCreditScoreVerified = computed(() => {
    return get(borrowerValues.value, 'personal_credit_score_verified.value') === "1"
  });

  const canChangeCreditScore = computed(() => {
    const creditScoreModified = get(borrowerValues.value, 'personal_credit_score_verified.modified')
    if (!isCreditScoreVerified || !creditScoreModified) {
      return true
    }
    const thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 30))
    return new Date(creditScoreModified) < thirtyDaysAgo
  });

  const creditScoreRePullDaysRemaining = computed(() => {
    const creditScoreModified = get(borrowerValues.value, 'personal_credit_score_verified.modified')
    const thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 30))
    return differenceInDays(new Date(creditScoreModified), thirtyDaysAgo);
  });

  const creditRange = computed(() => {
    const creditScore = Number(borrowerValues.value?.creditScore?.value)
    if (!creditScore || creditScore < 300) return {
      min: null,
      max: null,
    }
    const creditScoreVerified = borrowerValues.value?.personal_credit_score_verified?.value;
    if ((creditScoreVerified && creditScoreVerified !== "0") || creditPullStatus?.isExperianCustomer) {
      return experianCreditRanges.value.find(
        (range) => creditScore >= range.min && creditScore <= range.max
      )
    } else {
      const rangeId = lsUtils.creditScoreToOptionId(creditScore)
      return lsUtils.optionIdToCreditScore(rangeId)
    }
  });

  const isLenderSaasBorrower = computed(() => {
    return borrower.value && borrower.value.leadSource == 'Partner Import'
  });

  const unqualifiedIndustries = computed(() => {
    const unqualifiedIndustries = [
      'Adult Entertainment',
      'Gambling',
    ]
    return unqualifiedIndustries
  })

  const mineralGroup = computed(() => {
    const classificationIds = borrower.value.borrowerClassificationIds ?? '';
    if (classificationIds.includes(18)) return 'Diamond'
    if (classificationIds.includes(15)) return 'Platinum'
    if (classificationIds.includes(17)) return 'Gold'
    if (classificationIds.includes(21)) return 'SilverPlus'
    if (classificationIds.includes(19)) return 'Silver'
    return 'Coal'
  });

  const bankStatementsRequiredCount = computed(() => {
    const appType = progressStore.currentApp.type;
    if (appType === 'sba-complete') {
      return 6;
    }
    const bankStatementsRequired = get(borrower.value, "bankStatementsRequired");
    return bankStatementsRequired ?? legallyRequiredMonthsBack.value
  });

  const finicityStatementsCount = computed(() => {
    const appType = progressStore.currentApp.type;
    if (appType === 'sba-complete') {
      return 6;
    }
    const finicityBankStatementsCount = get(borrower.value, "finicityBankStatementsCount");
    return finicityBankStatementsCount ?? finicityMonthsBack.value
  });

  const isCoreOrPrime = computed(() => {
    const mineral = getMineral([], { borrowerValues: borrowerValues.value })
    return [mineralTypes.gold, mineralTypes.platinum, mineralTypes.diamond].includes(mineral)
  });

  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  async function connectAnonymousApplications() {
    if (!borrowerId.value) return;
    const payload = { anonymousId: rootStore.anonymousId }
    const url = `${env("apiUrl")}/borrower/${borrowerId.value}/borrower-applications/connect`
    const response = await $axios.put(url, payload)
    if (response?.status === 200) {
      rootStore.setAnonymousId(true)
    }
  }

  async function fetchApplicationRequirements() {
    const currentBorrower = await this.getBorrower();
    if (!currentBorrower || !currentBorrower.id) return;
    const appRequirements = await $axios
      .get(
        `${env("apiUrl")}/borrower/${
          currentBorrower.id
        }/application-requirements`,
        {
          params: {
            attributes: ["contact.ssn", "federalstate_tax_id"],
          },
        }
      )
      .then((response) => {
        return get(response, "data.data");
      });
    if (appRequirements.valueRequirements) {
      appRequirements.valueRequirements =
        appRequirements.valueRequirements.filter((req) => {
          return any(req.fields, (field) => !field.value);
        });
    }

    applicationRequirements.value = appRequirements;
    appRequirementsLoaded.value = true;
  }

  function clearAppRequirementsByAliases(aliases) {
    if (applicationRequirements.value.valueRequirements) {
      applicationRequirements.value.valueRequirements =
        applicationRequirements.value.valueRequirements.filter(
          (req) => !aliases.includes(req.key)
        );
    }
  }

  async function getAdditionalBorrowersWithContacts(borrowerId) {
    const currentUser = get(rootStore.authUser, "id")
      ? rootStore.authUser
      : await rootStore.getCurrentUser({ bypassRedirect: true });

    if (!currentUser || typeof currentUser.id === "undefined") return;

    const url = `${env("apiUrl")}/user/${
      currentUser.id
    }/borrower/${borrowerId}/borrowers`;

    const response = await $axios.get(url).catch(function (error) {
      // Do error handling
    });
    const additionalBorrowers = get(response, "data.data");

    if (Array.isArray(additionalBorrowers) && additionalBorrowers.length > 0) {
      additionalBorrowersWithContacts.value = additionalBorrowers;
    } else {
      const id = "new" + Date.now();
      const contacts = [{ id }];
      const borrowerValues = [];
      const name = "";
      additionalBorrowersWithContacts.value = [
        { borrowerValues, contacts, id, name },
      ];
    }
  }

  async function getBorrower({
    forceReload = false,
    requestedBorrowerId = null,
  } = {}) {
    // if a specific borrowerId was requested then we will want to override the existing borrower and retrieve as well as reset the progress
    if (requestedBorrowerId && requestedBorrowerId != borrowerId.value) {
      forceReload = true
      borrowerId.value = requestedBorrowerId
    }

    if (borrowerId.value && borrower.value?.id && !forceReload) return borrower.value;
    const authUser = rootStore.authUser;
    const authToken = rootStore.authToken;

    const currentUser = get(authUser, "id")
      ? authUser : await rootStore.getCurrentUser({ bypassRedirect: true });

    if (!currentUser || typeof currentUser.id === "undefined") {
      return;
    }

    const borrowerIdCookie = $lendioCookies.get("borrowerId");
    const pullBorrowerFromUser = borrowerId.value || borrowerIdCookie;
    const selectedBorrowerRequestUrl = `${env("apiUrl")}/user/${get(
      currentUser,
      "id"
    )}/borrower`;
    const defaultBorrowerRequestUrl = `${env('apiUrl')}/borrower/${pullBorrowerFromUser}/client`

    let borrowersInfoRequest = $axios.get(pullBorrowerFromUser ? defaultBorrowerRequestUrl : selectedBorrowerRequestUrl)
        .catch(async (e) => {
        console.error(e);
        log.error("Unable to get borrowers", {
          err: e,
          errMessage: e.message,
          currentUserId: currentUser.id,
          authToken: authToken ? authToken.substring(2, 7) : null,
          forceReload,
        });
        //Logout user if no borrower.
        await rootStore.logout({ $router: router })
      });

    let response = await borrowersInfoRequest;
    let _borrower = get(response, "data.data");
    if (!_borrower) {
      throw new Error(
        "No borrower data returned from borrower endpoint",
        response
      );
    }

    const userId = get(response, "data.data.userId");
    const repChanged = lenderUser.value && userId !== lenderUser.value.id;

    borrower.value = _borrower;
    borrowerId.value = _borrower.id;
    if (process.client && typeof window != "undefined" && window.dreams) {
      window.dreams.setBorrowerId(_borrower.id);
    }
    if (repChanged) {
      await this.getLenderUser(true);
    }
    return borrower.value;
  }

  async function getBorrowerBusinessContacts() {
    const currentBorrower = await this.getBorrower();
    if (!currentBorrower || !currentBorrower.id) return;
    if (borrowerBusinessContacts.value !== null)
      return borrowerBusinessContacts.value;
    try {
      let contacts = await $axios
        .$get(`${env("apiUrl")}/borrower/${currentBorrower.id}/contacts`)
        .then((response) => {
          return get(response, "data");
        });

      borrowerBusinessContacts.value = contacts;
    } catch (error) {
      log.error("borrower/getBorrowerBusinessContacts failed", error);
    }
  }

  async function getBorrowerValues({ forceReload = false, borrowerId = null } = {}) {
    const currentBorrower = await this.getBorrower();
    if ((!currentBorrower || !currentBorrower.id) && !borrowerId) return
    if (!borrowerId) {
      borrowerId = currentBorrower.id
    }
    // If borrower values are already loaded, update them to match the persisted answers
    if (borrowerValues.value && !forceReload) {
      const savedAnswers = questionsStore.savedAnswers;
      borrowerValues.value = Object.keys(savedAnswers).reduce(
        (borrowerValues, alias) => {
          const savedAnswer = get(savedAnswers, alias);
          if (
            borrowerValues.hasOwnProperty(alias) &&
            borrowerValues[alias].value != savedAnswer
          )
            borrowerValues[alias] = {
              ...borrowerValues[alias],
              value: savedAnswer,
            };
          return borrowerValues;
        },
        { ...borrowerValues.value }
      );
      return borrowerValues.value;
    }
    let res = await $axios
      .get(`${env("apiUrl")}/borrower-profile/${borrowerId}`)
      .catch((e) => {
        const authToken = rootStore.authToken;
        log.error("Unable to get borrower values", {
          err: e,
          errMessage: e.message,
          authToken: authToken ? authToken.substring(2, 7) : null,
          currentBorrowerId: borrowerId,
          forceReload,
        });
      });
    const values = get(res, "data.data");
    if (!values)
      throw new Error("No data returned from borrower profile endpoint", res);

    return setBorrowerValues(values)
  }

  function setBorrowerValues(values) {
    const keyedValues = values.reduce((acc, cur) => {
      acc[cur.alias] = cur;
      return acc;
    }, {});
    borrowerValues.value = keyedValues;
    // TODO: Add action for adobe target check
    return keyedValues;
  }

  function setBorrowerHasFundedDeals(value) {
    borrowerHasFundedDeals.value = value;
    if (typeof window != "undefined" && window.dreams) {
      if (!window.dreams.getData().hasOwnProperty("user.isRenewal")) {
        window.dreams.setData({ path: "user.isRenewal", data: value });
      }
    }
  }

  // Used for PWF signup and Embedded login flow(s)
  function setNewBorrowerId (newBorrowerId) {
    borrowerId.value = newBorrowerId
  }

  function setPWFBorrower(_borrower) {
    borrower.value = _borrower
    borrowerId.value = _borrower.id

    if (!lenderUser.value) {
      lenderUser.value = {}
    }

    lenderUser.value.id = _borrower.userId
    if (process.client && typeof window != 'undefined' && window.dreams) {
      window.dreams.setBorrowerId(_borrower.id)
    }
    setBorrowerHasFundedDeals(false)
  }

  async function getAvgMonthlySalesSource(){
    const currentBorrower = await this.getBorrower();
    if (!currentBorrower || !currentBorrower.id) {
      return;
    }

    let { data } = await $axios.get(`${env('apiUrl')}/borrower-value/${currentBorrower.id}/attribute/466/source`)
      .catch(e => {
        log.error('Error getting avgMonthlySales', {
          error: e,
          errorMessage: e.message,
          currentBorrowerId: currentBorrower.id,
        })
      })

    if (!Array.isArray(data?.data)){
      return;
    }

    avgMonthlySalesSource.value = data?.data.at(-1)?.source?.id
  }
  async function getCreditPullStatus() {
    const currentBorrower = await this.getBorrower()
    const _creditPullStatus = {
      isExperianCustomer: false,
      canRepullCredit: false,
      lastPullDate: null,
    };
    let res;
    try {
      res = await $axios.get(`${env('apiUrl')}/borrower-value/${currentBorrower.id}/attribute/66/source`)
    } catch(e) {
      log.error('Unable to get credit pull status', {
        error: e,
        errorMessage: e.message,
        currentBorrowerId: currentBorrower.id,
      })
    }
    const data = res?.data?.data;
    if (!data) {
      creditPullStatus.value = _creditPullStatus;
      return _creditPullStatus;
    }
    const filteredData = data
      .filter(
        (creditPull) =>
          creditPull.source?.id === 3 && creditPull.modified
      )
      .sort((a, b) => a.modified < b.modified ? 1: -1)
    const latestValue = filteredData[0];
    if (latestValue) {
      _creditPullStatus.isExperianCustomer = true;
      _creditPullStatus.lastPullDate = dateParseISO(latestValue.modified)
      _creditPullStatus.canRepullCredit = differenceInDays(Date.now(), _creditPullStatus.lastPullDate) > 30;
    }
    creditPullStatus.value = _creditPullStatus;
    return _creditPullStatus;
  }

  async function getBorrowerExperiments(forceReload = false) {
    const currentBorrower = await this.getBorrower();
    if (!currentBorrower || !currentBorrower.id) return;
    const authToken = rootStore.authToken;
    let res = await $axios
      .get(
        `${env("apiUrl")}/user/${currentBorrower.clientId}/experiments/active`
      )
      .catch((e) => {
        if (e.message !== "Request failed with status code 401") {
          log.error("Unable to get borrower experiments", {
            err: e,
            errMessage: e.message,
            authToken: authToken ? authToken.substring(2, 7) : null,
            currentBorrowerId: currentBorrower.id,
            forceReload,
          });
        }
      });
    const values = get(res, "data.data");
    if (!values) return;
    const experimentIds = values.map((exp) => exp["experimentId"]);
    const experimentsDetailed = values.map(({ experimentId, group, name }) => ({
      experimentId,
      group,
      name,
    }));
    borrowerExperimentsDetailed.value = experimentsDetailed;
    borrowerExperiments.value = experimentIds;
    return experimentIds;
  }

  async function getBusinessListing() {
    const currentBorrower = await this.getBorrower();
    if (!currentBorrower || !currentBorrower.id) return;
    if (businessListing.value !== null) return businessListing.value;
    const _businessListing = await $axios
      .get(`${env("apiUrl")}/b2b-directory/borrower/${currentBorrower.id}`)
      .then((response) => get(response, "data.data", null))
      .catch((e) => null);
    businessListing.value = _businessListing;
    return _businessListing;
  }

  async function getHasFundedDeals() {
    const currentBorrower = await this.getBorrower();
    if (!process.client || !currentBorrower || !currentBorrower.id) return;
    if (borrowerHasFundedDeals.value !== null)
      return borrowerHasFundedDeals.value;
    const fundedStatus = await $axios
      .get(`${env("apiUrl")}/borrower/${currentBorrower.id}/has-funded-deals`)
      .then((response) => {
        return get(response, "data.data");
      });
    borrowerHasFundedDeals.value = fundedStatus;
    if (typeof window != "undefined" && window.dreams) {
      if (!window.dreams.getData().hasOwnProperty("user.isRenewal")) {
        window.dreams.setData({ path: "user.isRenewal", data: fundedStatus });
      }
    }
    return fundedStatus;
  }

  async function getLastDeal() {
    const currentBorrower = await this.getBorrower();
    if (!process.client || !currentBorrower || !currentBorrower.id) return;
    if (borrowerLastDeal.value !== null) return borrowerLastDeal.value;
    const lastDeal = await $axios
      .get(`${env("apiUrl")}/borrower-portal/borrower-last-deal/${currentBorrower.id}`)
      .then((response) => {
        return get(response, "data.data", {
          status: null,
          stage: null
        });
      });
      borrowerLastDeal.value = lastDeal;
    return lastDeal;
  }

  async function getLastStatusChange() {
    const currentBorrower = await this.getBorrower();
    if (!process.client || !currentBorrower || !currentBorrower.id) return;
    if (borrowerLastStatus.value !== null) return borrowerLastStatus.value;
    const lastStatus = await $axios
      .get(`${env("apiUrl")}/borrower/${currentBorrower.id}/last-status`)
      .then((response) => {
        return get(response, "data.data");
      });
    borrowerLastStatus.value = lastStatus;
    return lastStatus;
  }

  async function setApplicationComplete({ type = null } = {}) {
    type = type ? type : progressStore.currentApp.type

    const currentBorrower = await this.getBorrower();
    if (!currentBorrower) return;

    // Ensure borrower application is synced across data locations
    await progressStore.updateApplication({ update: "complete", type });

    if (type !== 'sba-complete') {
      await $axios
        .put(
          `${env("apiUrl")}/borrower/${currentBorrower.id}`,
          JSON.stringify({ applicationComplete: true }),
          { headers: { "Content-Type": "application/json" } }
        )
        .then((res) => {
          const appComplete = get(res, "data.data.applicationComplete");
          borrower.value.applicationComplete = appComplete;
        })
        .catch((err) => {
          log.error("Error saving applicationComplete", { err });
        });
    }
  }

  async function setBorrowerZipId(zipId) {
    const currentBorrower = await this.getBorrower();
    if (!currentBorrower) return;

    await $axios
      .put(
        `${env("apiUrl")}/borrower/${currentBorrower.id}`,
        JSON.stringify({ zipId: zipId }),
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        borrower.value = res.data.data;
        borrowerId.value = res.data.data.id;
      })
      .catch((err) => {
        log.error("Error saving zipId", { err });
      });
  }

  async function setAssignedLenderUser(lenderUserId) {
    const currentBorrower = await this.getBorrower();
    if (!currentBorrower) return;

    await $axios
      .put(
        `${env("apiUrl")}/borrower/${currentBorrower.id}/assigned-lender`,
        JSON.stringify({ assignedLenderId: lenderUserId }),
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        const repChanged =
          lenderUser.value && lenderUserId !== lenderUser.value.id;
        borrower.value = res.data.data;
        borrowerId.value = res.data.data.id;
        if (repChanged) {
          this.getLenderUser(true);
        }
      })
      .catch((err) => {
        log.error("Error saving assignedLenderId", { err });
      });
  }

  async function getLenderUser(forceReload = false) {
    const currentBorrower = await this.getBorrower()
    if (!currentBorrower) return

    if (!forceReload && lenderUser.value?.id) return lenderUser.value;

    await $axios.get(`${env('apiUrl')}/borrower/${currentBorrower.id}/funding-manager`)
      .then(res => {
        const _lenderUser = res.data.data
        if (!_lenderUser) return
        // Removes extra un-needed information from _lenderUser so we're not working with such huge objects.
        const cleanUser = {
          'id': _lenderUser.id,
          'fullName': _lenderUser.fullName,
          'mobilePhone': _lenderUser.settings.mobilePhone,
          'twilioPhone': _lenderUser.settings.twilioNumber,
          'workPhone': _lenderUser.settings.workPhone,
          'email': _lenderUser.user.email,
          'pipelineEmail': _lenderUser.settings.lendioEmailPrefix + '@' + _lenderUser.settings.lendioEmailSuffix,
          'firstName': _lenderUser.user.first,
          'lastName': _lenderUser.user.last,
          'isSystemAccount': _lenderUser.isSystemAccount
        }
        lenderUser.value = cleanUser
        lenderUserLoaded.value = true
      })
      .catch(err => {
        const status = get(err, 'response.status')
        if (status !== 401) {
          log.error('Error getting the lender user', { err })
        }
      })
  }

  async function signApplication(payload = {}) {
    const currentBorrower = await this.getBorrower()
    if (!currentBorrower) return
    let res = await $axios.put(`${env('bpApiUrl')}/borrower/${currentBorrower.id}/esignature`, JSON.stringify(Object.assign({ signed: true }, payload)), { headers: { 'Content-Type': 'application/json' } })
    .catch(err => {
      log.error('Error signing application', { err })
    })
    signatureExpired.value = false
    esignatureAttempted.value = true
    // Ensure borrower application is synced across signature locations and rehydrate BV's
    await Promise.all([
      progressStore.updateApplication({ update: 'sign' }),
      useBorrowerStore(this.$pinia).getBorrowerValues({ forceReload: true })
    ])
    return res
  }

  async function findAndSetLenderUserStatus() {
    const currentBorrower = await this.getBorrower()
    let isLenderUserOnline = false
    const Authorization = userStore.getAuthHeader()
    const pusherKey = env('pusherKey')
    if (!Authorization || !pusherKey) {
      return false
    }

    // Connect to pusher itself
    let pusherInstance = new Pusher(pusherKey, {
      cluster: env('pusherCluster'),
      channelAuthorization: {
        endpoint: `${env('apiUrl')}/authorization/pusher`,
        headers: {Authorization}
      },
    })
    const lenderUserChannelName = `presence-lendio-${env('environment')}-notification-channel`
    const lenderUserPresenceChannel = await pusherInstance.subscribe(lenderUserChannelName)
    lenderUserPresenceChannel.bind('pusher:subscription_succeeded', function (data) {
      let onlineFundingManagers = Object.values(data.members)
      let onlineFundingManagerIds = []
      onlineFundingManagers.map(manager => {
        if (manager.role === 'lender') {
          onlineFundingManagerIds.push(manager.id)
        }
      })
      onlineFundingManagerIds.includes(currentBorrower.userId) ? isLenderUserOnline = true : null
      lenderUserOnline.value = isLenderUserOnline
      pusherInstance.unsubscribe(lenderUserChannelName)
    })
  }

  async function fetchLegalTexts() {
    if (legalTextsLoaded.value) {
      return legalTexts.value
    }

    return await $axios.get(`${env('apiUrl')}/legal-texts`, { params: { slugs: legalTextTypes.value } })
      .then(res => {
        const resData = get(res, 'data.data')
        legalTexts.value = Object.assign({}, legalTexts.value, resData);
        return resData
      })
      .catch(err => {
        log.warning('[LegalText] - Error Fetching Legal Texts', {
          requestUrl: `${env('apiUrl')}/legal-texts`,
          err: err,
        })
      })
  }

  async function setSignupBorrower({ borrowerData, cobrandAffiliateId }) {
    const _signupBorrower = await SignupBorrowerService.getSignupBorrowerFormData({
      answers: get(questionsStore, 'answers'),
      borrowerData,
      cobrandAffiliateId,
      $lendioCookies
    })
    signupBorrower.value = _signupBorrower
  }

  async function createNewContact(contactInformation) {
    await $axios.post(`${env('apiUrl')}/contact`, { borrowerId: borrower.value.id, ...contactInformation })
      .then(res => {
      })
      .catch(e => {
        log.error('Unable to create a new borrower contact', {
          err: e,
          errMessage: e.message,
          authToken: rootStore.authToken ? rootStore.authToken.substring(2, 7) : null
        })
      })
  }

  async function updateContact(contactInformation) {
    await $axios.put(`${env('apiUrl')}/contact/${contactInformation.id}`, { borrowerId: borrower.value.id, ...contactInformation })
      .then(res => {
      })
      .catch(e => {
        log.error('Unable to update the contact', {
          err: e,
          errMessage: e.message,
          authToken: rootStore.authToken ? rootStore.authToken.substring(2, 7) : null
        })
      })
  }

  async function getBusinessId() {
    await $axios.get(`${env('apiUrl')}/borrower/${borrowerId.value}/business`).then(response => {
      const result = get(response, 'data.data')
      businessId.value = result.businessId
      return get(response, 'data.data')
    })
  }

  async function createBorrower({
    capturePageRoute = '',
    experience = 'marketplace',
    postUrl = '/borrower',
  }) {
    let borrowerData = {}
    if (signupBorrower.value) {
      borrowerData = signupBorrower.value
    }

    capturePageRoute && $lendioCookies.set('capturepage', capturePageRoute)

    if (typeof window !== 'undefined' && window.dreams) {
      window.dreams.setEvent("createdAccount", "true")
    }

    // set tenantId so the user/borrower gets created for the correct tenant
    const tenantId = affiliateCustomizationStore.tenantId
    const createRes = await UserService.createBorrower(borrowerData, postUrl, experience, $axios, tenantId)
    borrower.value = createRes.borrower;
    borrowerId.value = createRes.borrowerId;
    return createRes
  }

  async function getBankStatementsMonthsBack() {
    const bankStatementsRequired = get(borrower.value, "bankStatementsRequired");
    const finicityBankStatementsCount = get(borrower.value, "finicityBankStatementsCount");
    if (bankStatementsRequired && finicityBankStatementsCount) {
      return
    }
    await $axios.get(`${env('apiUrl')}/borrower/${borrowerId.value}/required-statement-months`).then(response => {
      const result = get(response, 'data.data')
      finicityMonthsBack.value = result.finicityMonthsBack
      legallyRequiredMonthsBack.value = result.bankStatementsRequired
      return result
    })
  }

  async function deleteBorrower(borrowerId) {
    return $axios.delete(`${env('apiUrl')}/borrower/${borrowerId}`)
      .catch(e => {
        console.log(e)
      })
  }

  async function getPossibleLoansEstimation (force = false) {
    if (possibleLoansEstimation.value && !force) {
      return possibleLoansEstimation.value
    }

    try {
      const payload = { categoryLimit: 4 }
      const response = await $axios.post(
        `${env('apiUrl')}/borrower/${borrowerId.value}/estimate-possible-loan`,
        payload
      )

      const result = get(response, 'data.data', null)
      possibleLoansEstimation.value = result
      return result
    } catch (e) {
      log.error('Unable to fetch possible loans', {
        err: e,
        errMessage: e.message,
      })
    }
  }

  async function getBorrowerInsights () {
    let response;
    try {
      response = await $axios.get(`${env('apiUrl')}/borrower/${borrowerId.value}/borrower-insights`);
      if (response.statusCode > 299) {
        throw new Error(`Failed with code: ${response.statusCode}`, {
          cause: response.errors
        });
      } else if (!response.data?.data) {
        throw new Error('Response data from API should not be empty');
      }
    } catch (e) {
      log.error(e.message, {
        err: e,
        errMessage: e.message,
      });
    } finally {
      isBorrowerInsightsLoaded.value = true;
    }
    borrowerInsights.value = response?.data?.data;
    return response;
  }

  async function getRenewalEligibilityDate(force) {
    if (estimatedRenewalEligibility.value && !force) {
      return estimatedRenewalEligibility.value
    }

    try {
      const response = await $axios.get(`${env('apiUrl')}/borrower/${borrowerId.value}/renewal-eligibility-date`)

      const result = get(response, 'data.data.estimatedRenewalEligibility', null)
      estimatedRenewalEligibility.value = result
      return result
    } catch (e) {
      log.error('Unable to fetch estimated renewal eligibility date', {
        err: e,
        errMessage: e.message,
        borrowerId: borrowerId.value,
      })
    }
  }

  return {
    //STATE
    additionalBorrowersUnsavedAnswers,
    additionalBorrowersWithContacts,
    affiliatedBusinesses,
    appRequirements,
    applicationRequirements,
    appRequirementsLoaded,
    avgMonthlySalesSource,
    b2bContactData,
    b2bContactModal,
    b2bEditData,
    b2bEditModal,
    borrower,
    borrowerApplicationProgress,
    borrowerBusinessContacts,
    borrowerExperiments,
    borrowerExperimentsDetailed,
    borrowerHasFundedDeals,
    borrowerId,
    borrowerInsights,
    borrowerLastStatus,
    borrowerValues,
    businessId,
    businessListing,
    creditPullStatus,
    esignatureAttempted,
    estimatedRenewalEligibility,
    finicityMonthsBack,
    hasSunriseAccount,
    isBorrowerInsightsLoaded,
    legallyRequiredMonthsBack,
    legalTexts,
    lenderUser,
    lenderUserLoaded,
    lenderUserOnline,
    returningUserParams,
    signatureExpired,
    signupBorrower,
    submitting,

    //GETTERS
    bankStatementsRequiredCount,
    canChangeCreditScore,
    creditRange,
    creditScoreRePullDaysRemaining,
    debtScheduleComplete,
    finicityStatementsCount,
    getAppRequirements,
    getBorrowerId,
    getBorrowerUserId,
    getEmbeddedOrganization,
    getLegalTextBySlug,
    getMonthsInBusiness,
    hasBusinessDebt,
    isCoreOrPrime,
    isCreditScoreVerified,
    isEmbeddedEligible,
    isLenderSaasBorrower,
    legalTextsLoaded,
    lenderUserAvatarUrl,
    marketplaceCBI,
    mineralGroup,
    mpApplicationComplete,
    showLegalModal,
    unqualifiedIndustries,

    //ACTIONS
    fetchApplicationRequirements,
    clearAppRequirementsByAliases,
    connectAnonymousApplications,
    getAdditionalBorrowersWithContacts,
    getAvgMonthlySalesSource,
    getBorrower,
    getBorrowerBusinessContacts,
    getBorrowerValues,
    getCreditPullStatus,
    getBorrowerExperiments,
    getBusinessListing,
    getHasFundedDeals,
    getLastDeal,
    getLastStatusChange,
    fetchLegalTexts,
    setApplicationComplete,
    setBorrowerZipId,
    setAssignedLenderUser,
    getLenderUser,
    signApplication,
    findAndSetLenderUserStatus,
    setSignupBorrower,
    createNewContact,
    updateContact,
    getBusinessId,
    createBorrower,
    getBankStatementsMonthsBack,
    deleteBorrower,
    getPossibleLoansEstimation,
    getBorrowerInsights,
    setBorrowerValues,
    setBorrowerHasFundedDeals,
    setNewBorrowerId,
    setPWFBorrower,
    getRenewalEligibilityDate,
  };
});
