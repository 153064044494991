export default {
  "affiliate-intro": () => import("/frontend/layouts/affiliate-intro.vue"),
  "app-config": () => import("/frontend/layouts/app-config.vue"),
  auth: () => import("/frontend/layouts/auth.vue"),
  "base-portal": () => import("/frontend/layouts/base-portal.vue"),
  base: () => import("/frontend/layouts/base.vue"),
  default: () => import("/frontend/layouts/default.vue"),
  "document-upload-request": () => import("/frontend/layouts/document-upload-request.vue"),
  "embedded-base": () => import("/frontend/layouts/embedded-base.vue"),
  "embedded-portal": () => import("/frontend/layouts/embedded-portal.vue"),
  embedded: () => import("/frontend/layouts/embedded.vue"),
  identity: () => import("/frontend/layouts/identity.vue"),
  iframe: () => import("/frontend/layouts/iframe.vue"),
  light: () => import("/frontend/layouts/light.vue"),
  marketplace: () => import("/frontend/layouts/marketplace.vue"),
  portal: () => import("/frontend/layouts/portal.vue"),
  renewal: () => import("/frontend/layouts/renewal.vue"),
  sba: () => import("/frontend/layouts/sba.vue")
}