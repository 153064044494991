function getName (type) {
    return {
      '401k': '401k Financing',
      abl: 'Asset Based Loan',
      ach: 'ACH Cash Flow Loan',
      cre: 'Commercial Real Estate',
      crowdfunding: 'Crowdfunding',
      creditCard: 'Business Credit Card',
      equipment: 'Equipment',
      factoring: 'Factoring (AR/PO)',
      loc: 'Line of Credit',
      mca: 'Merchant Cash Advance',
      purchase: 'Purchase',
      personal: 'Personal Loan',
      sba: 'Large SBA',
      sbaExpress: 'Express SBA',
      term: 'Term Loan',
      unsecured: 'Unsecured Term Loan'
    }[type]
  }
  function getInfoPath (type) {
    return {
      '401k': '/blog/collateral-to-get-small-business-loan-lendio-whiteboards-episode-6/',
      'abl': '/blog/asset-based-small-business-loan/',
      'ach': '/blog/ach-loan-want-one/',
      'cre': '/business-loans/commercial-real-estate/',
      'creditCard': '/blog/what-is-a-business-credit-card/',
      'equipment': '/business-loans/equipment-financing/',
      'factoring': '/business-loans/accounts-receivable-financing/',
      'loc': '/business-loans/line-of-credit/',
      'mca': '/business-loans/cash-advance/',
      'personal': '/business-loans/startup-loans/',
      'sba': '/business-loans/sba-loans/',
      'sbaExpress': '/business-loans/sba-loans/',
      'term': '/business-loans/term-loans/',
      'unsecured': '/business-loans/'
    }[type]
  }
  const sbaLoanCategoryTypes = [
    "sbaStandard",
    "sba",
    "sbaExpress",
    "sbaStartup",
    "sba504",
    "sbaPpp",
    "sbaPPPSecond",
  ]

  export {
    getName,
    getInfoPath,
    sbaLoanCategoryTypes,
  }
  