import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

import get from 'lodash/get'

import { getOffer, trackEvent } from '~/libs/adobe-target'
import { getCacheKey } from '~/libs/adobe-target'
import env from '~/libs/env'

import { useBorrowerStore } from '~/store/borrower'
import { usePortalStore } from '~/store/portal'
import { useRootStore } from '~/store/root'
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'
import AnalyticsService from '~/libs/analytics-service'

export const useAppAnalyticsStore = defineStore('appAnalytics', () => {
  const nuxtApp = useNuxtApp()
  const { $lendioCookies, $axios } = nuxtApp

  const affiliateCustomizationStore = useAffiliateCustomizationStore()
  const borrowerStore = useBorrowerStore()
  const portalStore = usePortalStore()
  const rootStore = useRootStore()

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
  */
  const searchedOffers = ref({})
  const trackedCookies = ref({})

  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
  */

  const serviceAvailable = computed(() => {
    return (tag, liveOnly = false) => {
      if (process.client && env('environment') === 'dev' && env('ADOBE_TARGET_DEV') === 'true') {
        return true
      }
      const checkLive = liveOnly ? env('environment') === 'LIVE' : true
      return checkLive && process.client && typeof window[tag] !== 'undefined' && window[tag]
    }
  })

  // Boolean response saying whether 1) we have already received an offer
  // (hasCheckedMbox) and 2) it matches our searchTerm.
  const hasTargetOffer = computed(() => {
    return (mbox, searchTerm, { contains = false } = {}) => {
      const getTest = hasCheckedMbox.value(mbox, $lendioCookies)
      if (!getTest || !getTest.length || !searchTerm) {
        return false
      }
      const testOffers = getTest.map(targetOffer => targetOffer.content)
      return contains ? testOffers.some(offer => offer.indexOf(searchTerm) > -1)
        : testOffers.indexOf(searchTerm) > -1
    }
  })

  // See if an offer has been returned for a test (mbox). This getter just
  // lets you know that we already have an offer, independent of its value.
  const hasCheckedMbox = computed(() => {
    return (mbox) => {
      const cacheKey = getCacheKey(mbox)
      // if CacheKey is set to false, it will not check the cookie cache
      const cached = cacheKey && $lendioCookies.get(cacheKey)
      return cached && JSON.parse(decodeURIComponent(cached)) || get(searchedOffers.value, `[${mbox}].offer`)
    }
  })

  // Boolean response saying whether 1) we have already received an offer
  // (hasCheckedMbox) and 2) it matches our searchTerm.
  const getTargetOffer = computed(() => {
    return (mbox) => {
      const testOffers = hasCheckedMbox.value(mbox, $lendioCookies)
      if (!testOffers || !testOffers.length) {
        return null
      }
      return get(testOffers, '[0].content')
    }
  })

  const getLendioId = computed(() => {
    const dreamsAvailable = serviceAvailable.value('dreams')
    if (dreamsAvailable) {
      return get(window, 'dreams.dataLayer.user.lendioId', null)
    }
    if (process.client && window.localStorage.dreams) {
      const dreams = JSON.parse(window.localStorage.dreams)
      const lendioId = dreams.user.lendioId
      return lendioId
    }
    return null
  })

  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
  */


  /**
   * Initiate an adobe target test OR let us know whether an offer matching
   * our searchTerm has already been received.
   *
   * Pass an adobe payload to this with a {mbox, searchTerm, ?params} object.
   * Any `.NAME` field in the MBOX list in the `adobe-target.js` file can be
   * used for `mbox`.
   *
   * `searchTerm` is the offer value whose existence you're checking.
   * After you have called this method to initiate a test (with optional
   * params), call this again without params to get the current offer value
   * from the store.
   *
   * `params` is an optional object (only used with initial call) of its own
   * key-value pairs and is passed to ensure Target has everything it needs
   * to return a correct offer response.
   *
   * @param {*} adobePayload key-value pairs for `mbox`, `searchTerm`, and `params`
   * @returns {(boolean|null)} True if offer matches searchTerm.
   *                          False if offer exists but doesn't match.
   *                          null if no valid mbox or searchTerm params.
   */
  async function checkSingleTargetOffer(adobePayload = {}) {
    const { mbox, searchTerm, params, applyOffer } = adobePayload
    if (mbox && searchTerm) {

      // If we've already initiated the test with adobe target, this gets
      // the value from the store.
      if (hasCheckedMbox.value(mbox, $lendioCookies) && !params) {
        return hasTargetOffer.value(mbox, searchTerm, { $lendioCookies: $lendioCookies })
      }

      if (serviceAvailable.value('adobe')) {
        // If this is a test borrower (email like *testEmailPrefix+*@lendio.com)
        // add this param so we can filter out test borrowers in test reports.
        const testBorrower = get(borrowerStore, 'borrower.isTest', null)
        const isLendioTenant = affiliateCustomizationStore.isLendioTenant
        const tenantId = affiliateCustomizationStore.tenantId
        let modifiedParams = {
          testBorrower,
          orderId: get(borrowerStore, 'borrower.id') || undefined,
          orderTotal: 0,
          productPurchasedId: 'da_event',
          isLendioTenant,
          tenantId,
        }
        if (params) modifiedParams = { ...params, ...modifiedParams }

        // This is where we get an offer value from adobe if borrower
        // qualifies to be part of this test's audience.
        const offerObj = await getOffer({ mbox, params: modifiedParams}, applyOffer)
        // Save the offer to the store. If user didn't qualify for this test,
        // we'll have a saved response in searchedOffers, but it won't match
        // our searchTerm.
        this.setSearchedOffers({[mbox]: offerObj})

        // Boolean of whether we have an offer that matches our searchTerm.
        return hasTargetOffer.value(mbox, searchTerm, { $lendioCookies: $lendioCookies })
      }
    }
    return null
  }

  /**
   * Checks the store for existing offers related to a test (mbox) OR
   * initiates a test to return an offer.
   *
   * Only difference between this and checkSingleTargetOffer is that we don't
   * check or initiate the offer based on a searchTerm.
   *
   * @param {Object} - Injects available services/methods by name within {}.
   * @param {Object} adobePayload - mbox (test name) and params
   * @returns {(boolean|null)} True if offer exists for a test/mbox.
   *                           False if offer exists but doesn't match.
   *                           null if no valid mbox or searchTerm params.
   */
  async function checkAllTargetOffers({mbox, params} = {}) {
    // Don't request without an mbox name
    if (!mbox) {
      return null
    }

    const checkedValue = hasCheckedMbox.value(mbox, $lendioCookies)
    if (checkedValue) return checkedValue

    if (!serviceAvailable.value('adobe')) {
      return null
    }

    // Add the boolean test borrower param so tests can filter in Target.
    const testBorrower = get(borrowerStore, 'borrower.isTest', null)
    const isLendioTenant = affiliateCustomizationStore.isLendioTenant
    const tenantId = affiliateCustomizationStore.tenantId
    let modifiedParams = {
      testBorrower,
      orderId: get(borrowerStore, 'borrower.id') || undefined,
      orderTotal: 0,
      productPurchasedId: 'da_event',
      isLendioTenant,
      tenantId,
    }
    if (params) modifiedParams = { ...params, ...modifiedParams }
    const offerObj = await getOffer({ mbox, params: modifiedParams })
    searchedOffers.value = {[mbox]: offerObj}
    return hasCheckedMbox.value(mbox, $lendioCookies)
  }

  async function adobeTrackEvent(adobePayload = {}) {
    const { mbox, params, success, error } = adobePayload
    if (serviceAvailable.value('adobe') && mbox) {
      const testBorrower = get(borrowerStore, 'borrower.isTest', null)
      const isLendioTenant = affiliateCustomizationStore.isLendioTenant
      const tenantId = affiliateCustomizationStore.tenantId
      let modifiedParams = {
        testBorrower,
        isLendioTenant,
        tenantId
      }
      if (params) modifiedParams = { ...params, ...modifiedParams }
      trackEvent(mbox, modifiedParams, success, error)
    }
    return
  }

  async function dreamsSetEvent(dreamsPayload = {}) {
    const { name, value } = dreamsPayload
    if (name && value && serviceAvailable.value('dreams')) {
      window.dreams.setEvent(name, value)
    }
    return false
  }

  async function setSearchedOffers(payload) {
    const mbox = Object.keys(payload).find(() => true),
    cacheKey = getCacheKey(mbox)
    if (cacheKey && process.client) {
      $lendioCookies.set(cacheKey, encodeURIComponent(JSON.stringify(payload[mbox].offer)), payload.options || {secure: env('environment') !== 'dev'})
    }
    searchedOffers.value = { ...searchedOffers.value, ...payload }
  }
  /**
   * Write passed variables to FullStory.
   *
   * These values are formatted according to fullstory's requirements and
   * used to create reporting segments.
   *
   * @param {*} values A Key-Value pair object with values to set in FullStory
   * @returns Promise true/false of success
   */
  async function fsUpdate(values = {}) {
    if (!process.client || !serviceAvailable.value('FS_launch')) {
      return false
    }
    const setUserVars = get(window, 'FS_launch.setUserVars');
    if (!setUserVars || !values) {
      throw new Error('Instantiated Fullstory is missing setUserVars')
    }
    const fsValues = Object.keys(values)
      .reduce((carry, key) => {
        let type = 'str'
        const val = values[key]
        if (/^-?\d+$/.test(val)) {
          type = 'int'
        } else if (/^-?\d*\.\d*$/.test(val)) {
          type = 'real'
        } else if (/^(?:true|false)$/.test(val)) {
          type = 'bool'
        }
        carry[`${key}_${type}`] = val
        return carry
      }, {})
    try {
      await setUserVars(fsValues)
    } catch (e) {
      console.error(e)
      return false
    }
    return true
  }

  /**
   * Save state of authenticated user to analytics services.
   *
   * The keys for the passed values cannot have underscores, as those will
   * fail in Fullstory validation.
   *
   * @param {*} values Key-Value pairings of the values passed
   * @returns Object with success boolean for each service. Currently only `fullstory` after pendo removed.
   */
  async function saveSharedState(values = {}) {
    // Ignore non-authenticated borrowers
    if (!process.client || !get(borrowerStore, 'borrower.id')) {
      return { fullstory: false }
    }

    // Save to and fullstory.
    const servicesSaved = {}
    const [ fullstory ] = await Promise.all([
      this.fsUpdate(values)
        .catch(e => false),
    ])
    servicesSaved.fullstory = fullstory
    return servicesSaved
  }

  async function waitForService(tag) {
    return new Promise((resolve, reject) => {
      const maxAttempts = 1000
      let count = 0
      const checkService = setInterval(() => {
        if (serviceAvailable.value(tag) || count >= maxAttempts) {
          clearInterval(checkService)
          resolve(serviceAvailable.value(tag))
        }
      }, 200)
    })
  }

  /**
   * Load the tracked cookies (or query parameters) per the cookie service
   */
  async function loadTrackedCookies() {
    trackedCookies.value = $lendioCookies.trackingCookies.map(cookie => {
        return [
          cookie,
          $lendioCookies.get(cookie) || get(nuxtApp, `query["${cookie}"]`)
        ]
      })
      .filter(([cookie, val]) => Boolean(val))
      .reduce((carry, [cookie, val]) => (carry[cookie] = val, carry), {})
  }

  /**
   * Load the tracked cookies (or query parameters) per the cookie service
   */
   async function clearTrackedCookies() {
    trackedCookies.value = {}
    $lendioCookies.trackingCookies.forEach(cookie => $lendioCookies.remove(cookie))
  }

  async function fsTrackEvent({ name, properties = {}}) {
    if (!serviceAvailable.value('FS_launch')) {
      return log.error(new Error('Fullstory services not available'))
    }
    if (!name) {
      return log.warning('Name required to post Fullstory event.')
    }
    try {
      window.FS_launch('trackEvent', {
        name,
        properties
      })
    } catch (err) {
      return log.warning('Error posting Fullstory event', err)
    }
  }
/*
  Create faux tracking route in borrowerPagePortalPageViews
  Useful for tracking component stage changes that do not actually change page/url
  e.g. Manual upload state on /marketplace-app/documents/ would be faux route /marketplace-app/documents?state=manual
 */
  async function fauxBorrowerPageTrackingEvent(fauxRouteName) {
    if (!fauxRouteName) return
    AnalyticsService.trackRoute(
      borrowerStore.borrower ? borrowerStore.borrower.id : null,
      getLendioId.value,
      fauxRouteName,
      $axios
    )
  }

  return {
    // STATE
    searchedOffers,
    trackedCookies,

    // GETTERS
    serviceAvailable,
    hasTargetOffer,
    getTargetOffer,
    hasCheckedMbox,
    getLendioId,

    // ACTIONS
    checkSingleTargetOffer,
    checkAllTargetOffers,
    adobeTrackEvent,
    dreamsSetEvent,
    setSearchedOffers,
    fsTrackEvent,
    fsUpdate,
    saveSharedState,
    waitForService,
    loadTrackedCookies,
    clearTrackedCookies,
    fauxBorrowerPageTrackingEvent,
  }
})
