export function timestampToTimezoneDate(timestamp, tz = 'America/Denver') {
  const originalDate = new Date(timestamp)
  try {
    const date = new Date(new Date(timestamp).toLocaleString('en-US', {timeZone: tz}))

    const diff = originalDate.getTime() - date.getTime()
    return new Date(originalDate.getTime() - diff)
  } catch (err) {
    console.error('Cannot convert date to timezone: ' + tz)
    return originalDate;
  }
}

export function compatibleDate(date) {
  //This is a fix for safari
  if (typeof date === 'string') {
    return date.replace(' ', 'T')
  } if (typeof date === 'number') {
    if (Math.log10(date) < 12) {
      return date * 1000
    }
  }
  return date
}
