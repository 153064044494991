const ignoredHosts = {
  allow: [
    'www.lendio.com',
    'lendio.com',
    'bp.production.corp.lendio.net',
    'cornerbank.lendio.com',
    'lendio.my.dnb.com',
  ]
}

const ignoredErrors = [
  { type: 'AxiosError', code: 'ECONNABORTED' },
  { type: 'AxiosError', code: 'ERR_BAD_REQUEST' },
  { type: 'AxiosError', code: 'ERR_NETWORK' },
];

function logCaughtError(log, err, context) {
  // collect error details
  context.error = {
    message: err.message ?? null,
    type: err.constructor.name ?? null,
    code: err.code ?? null,
    stack: err.stack ?? null,
  }

  // check if ignored
  const ignored = ignoredErrors.some(x => x.type === context.error.type && x.code === context.error.code)
  if (ignored) {
    return
  }

  // build message and log
  const message = `iframe-only-check ERROR ${context.host}: ${context.error.code} ${context.error.type} ${context.error.message}`
  log.error(message, context)
}

function logBlock(log, context) {
  log.error(`iframe-only-check BLOCK ${context.host}: !iframe && iframeOnly`, context)
}

export async function iframeOnlyCheck({ identityStore, requestURL, log }) {
  // we don't have a reliable way to detect iframe use case server side
  if (process.server) {
    throw new Error('iframeOnlyCheck may only be executed client side')
  }

  const context = {
    server: process.server,
    host: requestURL.host,
  }

  try {
    // in iframe?
    const iframe = window.self !== window.top
    context.iframe = iframe

    // allow iframe
    if (iframe) {
      return
    }

    // allow if not iframe only origin
    let iframeOnly = false;
    try {
      iframeOnly = await identityStore.isIframeOnly()
      context.iframeOnly = iframeOnly
    } catch (err) {
      logCaughtError(log, err, context)
      return
    }
    if (!iframeOnly) { // allow
      return
    }

    // block, non-iframe on iframe only origin
    logBlock(log, context)
    return showError({
      statusCode: 404,
      statusMessage: 'Page not found'
    })
  } catch (err) {
    logCaughtError(log, err, context)
  }
}
