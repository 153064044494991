import { compatibleDate } from '~/libs/date-helpers'

function getOfferPayback(offer) {
  if (!offer) {
    return null;
  }
  if (offer.payback) {
    return Number(offer.payback)
  }
  if (!offer.factorRate || !offer.amount) {
    return null;
  }
  const factorRate = Number(offer.factorRate)
  // calculate payback only for valid factor rate
  if (factorRate < 1 && factorRate >= 2) {
    return null;
  }
  return offer.amount * factorRate
}

function getApprovalMaxAmount(approval, option) {
  switch (approval?.loanProductCategoryType) {
    case 'ach':
      return option?.maxAmount
    case 'loc':
      return option?.limits?.drawAmount?.max
    case 'term':
      return option?.limits?.amount?.max
    default:
      return option?.maxAmount
  }
}

function getApprovalOption(approval, initialChange) {
  const optionId = initialChange(approval)?.optionId || null;
  let optionIndex = approval.options?.findIndex(option => option.id === optionId) || 0;
  if (optionIndex < 0) {
    optionIndex = 0;
  }
  return approval.options?.[optionIndex] ?? {};
}

function getOptionLenderId(option) {
  return option?.loanProductLenderId || option?.lenderId || null
}

export function sortOptions (output, initialChange, requestedAmount, prioritizedLenderId, a, b) {
  const aOption = !a.isStaticOffer ? getApprovalOption(a, initialChange) : {};
  const bOption = !b.isStaticOffer ? getApprovalOption(b, initialChange) : {};

  if (prioritizedLenderId) {
    const aLenderId = getOptionLenderId(a)
    const bLenderId = getOptionLenderId(b)

    if (aLenderId !== bLenderId) {
      if (aLenderId === prioritizedLenderId) {
        return -1
      } else if (bLenderId === prioritizedLenderId) {
        return 1
      }
    }
  }

  const firstAmount = Number(a.isStaticOffer ? a.amount : getApprovalMaxAmount(a, aOption));
  const secondAmount = Number(b.isStaticOffer ? b.amount : getApprovalMaxAmount(b, bOption));

  if (firstAmount === secondAmount || (!Number.isFinite(firstAmount) && !Number.isFinite(secondAmount))) {
    const typeOrder = ['loc', 'term', 'ach'].reverse()
    const firstLoanTypePriority = typeOrder.indexOf(a?.loanProductCategoryType) + 1;
    const secondLoanTypePriority = typeOrder.indexOf(b?.loanProductCategoryType) + 1;

    if (firstLoanTypePriority !== secondLoanTypePriority) {
      return secondLoanTypePriority - firstLoanTypePriority
    }

    if (!Number.isFinite(firstAmount) && !Number.isFinite(secondAmount)) {
      return new Date(compatibleDate(a.created)) - new Date(compatibleDate(b.created))
    }

    const firstPayback = !a.isStaticOffer && a.options.length
      ? output(a, { optionId: aOption.id })?.payback
      : getOfferPayback(a)

    const secondPayback = !b.isStaticOffer && b.options.length
      ? output(b, { optionId: bOption.id })?.payback
      : getOfferPayback(b)

    if (!firstPayback && !secondPayback) {
      return new Date(compatibleDate(a.created)) - new Date(compatibleDate(b.created))
    }

    if (!firstPayback) {
      return 1
    } else if (!secondPayback) {
      return -1
    }

    const firstCostOfCapital = firstPayback - firstAmount
    const secondCostOfCapital = secondPayback - secondAmount

    return firstCostOfCapital - secondCostOfCapital
  }

  if (!Number.isFinite(firstAmount)) {
    return 1
  } else if (!Number.isFinite(secondAmount)) {
    return -1
  }

  // sort from max to min amount if no requested
  if (!requestedAmount) {
    return secondAmount - firstAmount
  }

  // sort from min to max to meet closest requested amount
  if (firstAmount >= requestedAmount && secondAmount >= requestedAmount) {
    return firstAmount - secondAmount
  }

  if (firstAmount >= requestedAmount) {
    return -1
  } else if (secondAmount >= requestedAmount) {
    return 1
  }

  // sort from max to min amount if loan amount less than requested
  return secondAmount - firstAmount
}

export function sortOptionsByParam (param, output, initialChange, a, b) {
  const splitParam = param.split('-')
  const direction = splitParam.pop() === 'asc' ? 'asc' : 'desc'
  const sortBy = splitParam.join('-')

  switch (sortBy) {
    case 'amount':
      return sortByAmount(a, b, direction, output, initialChange)
    case 'cost':
      return sortByCost(a, b, direction, output, initialChange)
    case 'term':
      return sortByTerm(a, b, direction, output, initialChange)
    default:
      if (direction === 'asc') {
        return new Date(compatibleDate(a.created)) - new Date(compatibleDate(b.created))
      } else {
        return new Date(compatibleDate(b.created)) - new Date(compatibleDate(a.created))
      }
  }
}

function sortByAmount(a, b, direction, output, initialChange) {
  const aOption = !a.isStaticOffer ? getApprovalOption(a, initialChange) : {};
  const bOption = !b.isStaticOffer ? getApprovalOption(b, initialChange) : {};

  const firstAmount = Number(a.isStaticOffer ? a.amount : getApprovalMaxAmount(a, aOption));
  const secondAmount = Number(b.isStaticOffer ? b.amount : getApprovalMaxAmount(b, bOption));

  if (firstAmount === secondAmount || (!Number.isFinite(firstAmount) && !Number.isFinite(secondAmount))) {
    const typeOrder = ['loc', 'term', 'ach'].reverse()
    const firstLoanTypePriority = typeOrder.indexOf(a?.loanProductCategoryType) + 1;
    const secondLoanTypePriority = typeOrder.indexOf(b?.loanProductCategoryType) + 1;

    if (firstLoanTypePriority !== secondLoanTypePriority) {
      if (direction === 'asc') {
        return firstLoanTypePriority - secondLoanTypePriority
      } else {
        return secondLoanTypePriority - firstLoanTypePriority
      }
    }

    if (direction === 'asc') {
      return new Date(compatibleDate(a.created)) - new Date(compatibleDate(b.created))
    } else {
      return new Date(compatibleDate(b.created)) - new Date(compatibleDate(a.created))
    }
  }

  if (!Number.isFinite(firstAmount)) {
    return direction === 'asc' ? -1 : 1
  } else if (!Number.isFinite(secondAmount)) {
    return direction === 'asc' ? 1 : -1
  }


  if (direction === 'asc') {
    return firstAmount - secondAmount
  } else {
    return secondAmount - firstAmount
  }
}

function sortByCost(a, b, direction, output, initialChange) {
  const aOption = !a.isStaticOffer ? getApprovalOption(a, initialChange) : {};
  const bOption = !b.isStaticOffer ? getApprovalOption(b, initialChange) : {};

  const firstPayback = !a.isStaticOffer && a.options.length
    ? output(a, { optionId: aOption.id })?.payback
    : getOfferPayback(a)

  const secondPayback = !b.isStaticOffer && b.options.length
    ? output(b, { optionId: bOption.id })?.payback
    : getOfferPayback(b)

  const firstAmount = Number(a.isStaticOffer ? a.amount : getApprovalMaxAmount(a, aOption));
  const secondAmount = Number(b.isStaticOffer ? b.amount : getApprovalMaxAmount(b, bOption));

  const firstCostOfCapital = (firstPayback || 0) - firstAmount
  const secondCostOfCapital = (secondPayback || 0) - secondAmount

  if ((!firstPayback && !secondPayback) || (!Number.isFinite(firstAmount) && !Number.isFinite(secondAmount)) || firstCostOfCapital === secondCostOfCapital) {
    if (Number.isFinite(firstAmount) && Number.isFinite(secondAmount) && firstAmount !== secondAmount) {
      return direction === 'asc' ? firstAmount - secondAmount : secondAmount - firstAmount
    }
    if (Number.isFinite(firstAmount) && !Number.isFinite(secondAmount)) {
      return direction === 'asc' ? -1 : 1
    }
    if (Number.isFinite(secondAmount) && !Number.isFinite(firstAmount)) {
      return direction === 'asc' ? 1 : -1
    }

    const typeOrder = ['loc', 'term', 'ach'].reverse()
    const firstLoanTypePriority = typeOrder.indexOf(a?.loanProductCategoryType) + 1;
    const secondLoanTypePriority = typeOrder.indexOf(b?.loanProductCategoryType) + 1;

    if (firstLoanTypePriority !== secondLoanTypePriority) {
      if (direction === 'asc') {
        return firstLoanTypePriority - secondLoanTypePriority
      } else {
        return secondLoanTypePriority - firstLoanTypePriority
      }
    }

    if (direction === 'asc') {
      return new Date(compatibleDate(a.created)) - new Date(compatibleDate(b.created))
    } else {
      return new Date(compatibleDate(b.created)) - new Date(compatibleDate(a.created))
    }
  }

  if (!Number.isFinite(firstAmount) || !firstPayback) {
    return direction === 'asc' ? -1 : 1
  } else if (!Number.isFinite(secondAmount) || !secondPayback) {
    return direction === 'asc' ? 1 : -1
  }

  if (direction === 'asc') {
    return firstCostOfCapital - secondCostOfCapital
  } else {
    return secondCostOfCapital - firstCostOfCapital
  }
}

function sortByTerm(a, b, direction, output, initialChange) {
  const aOption = !a.isStaticOffer ? getApprovalOption(a, initialChange) : {};
  const bOption = !b.isStaticOffer ? getApprovalOption(b, initialChange) : {};

  const firstTerm = !a.isStaticOffer && a.options.length
    ? output(a, { optionId: aOption.id })?.term
    : a.term

  const secondTerm = !b.isStaticOffer && b.options.length
    ? output(b, { optionId: bOption.id })?.term
    : b.term

  if (firstTerm === secondTerm || (!firstTerm && !secondTerm)) {
    const typeOrder = ['loc', 'term', 'ach'].reverse()
    const firstLoanTypePriority = typeOrder.indexOf(a?.loanProductCategoryType) + 1;
    const secondLoanTypePriority = typeOrder.indexOf(b?.loanProductCategoryType) + 1;

    if (firstLoanTypePriority !== secondLoanTypePriority) {
      if (direction === 'asc') {
        return firstLoanTypePriority - secondLoanTypePriority
      } else {
        return secondLoanTypePriority - firstLoanTypePriority
      }
    }

    if (direction === 'asc') {
      return new Date(compatibleDate(a.created)) - new Date(compatibleDate(b.created))
    } else {
      return new Date(compatibleDate(b.created)) - new Date(compatibleDate(a.created))
    }
  }

  if (!firstTerm) {
    return direction === 'asc' ? -1 : 1
  } else if (!secondTerm) {
    return direction === 'asc' ? 1 : -1
  }

  if (direction === 'asc') {
    return Number(firstTerm) - Number(secondTerm)
  } else {
    return Number(secondTerm) - Number(firstTerm)
  }
}
