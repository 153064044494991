import { defineStore } from 'pinia'
import PartnersLib from '~/libs/portal-partners'
import get from 'lodash/get'
import { isValid, isBefore } from 'date-fns'
import { localStorageService } from '~/libs/local-storage.service'
import modalContent from '~/libs/modal-helper'
import {
  DAYS,
  EMBEDDED_PORTAL_NAV_TITLES,
  INTELLIGENT_LENDING_PORTAL_NAV_TITLES,
  PORTAL_APP_STATE,
  PORTAL_NAV_TITLES,
  PORTAL_NAV_URLS,
  EMBEDDED_NAV_LINKS,
  INTELLIGENT_LENDING_NAV_LINKS,
  NAV_LINKS,
  SBA_COMPLETE_NAV_LINKS
} from '~/libs/portal-constants'
import * as ScorecardLib from '~/libs/portal-scorecards'
import env from '~/libs/env'
import { MBOX_DETAILS } from '~/libs/adobe-target'

import { useAppAnalyticsStore } from '~/store/app-analytics'
import { useApprovalsStore } from '~/store/approvals'
import { useBorrowerStore } from '~/store/borrower'
import { useDealsStore } from '~/store/deals'
import { useDocumentsStore } from '~/store/documents'
import { useExperienceStore } from '~/store/experience'
import { useOwnershipStore } from '~/store/ownership'
import { useProgressStore } from '~/store/progress'
import { useUserStore } from '~/store/user'
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'

// Helper functions Getters
function checkDaysSinceAppComplete(appComplete) {
  if (appComplete) {
    const now = new Date().getTime() / DAYS
    const appCompleteTime = new Date(appComplete).getTime() / DAYS
    if (appCompleteTime) {
      return now - appCompleteTime
    }
  }
  return null
}

function checkAppCompleteInLast30Days(appComplete) {
  if (appComplete) {
    const daysSinceAppComplete = checkDaysSinceAppComplete(appComplete)
    return !daysSinceAppComplete || daysSinceAppComplete <= 30
  }
  return false
}
// End helper functions

export const usePortalStore = defineStore('portal', () => {
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp

  const appAnalyticsStore = useAppAnalyticsStore()
  const approvalsStore = useApprovalsStore()
  const borrowerStore = useBorrowerStore()
  const dealsStore = useDealsStore()
  const documentsStore = useDocumentsStore()
  const experienceStore = useExperienceStore()
  const ownershipStore = useOwnershipStore()
  const progressStore = useProgressStore()
  const userStore = useUserStore()
  const affiliateCustomizationStore = useAffiliateCustomizationStore()

/*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const navLinks = ref(NAV_LINKS)
  const embeddedNavLinks = ref(EMBEDDED_NAV_LINKS)
  const intelligentLendingNavLinks = ref(INTELLIGENT_LENDING_NAV_LINKS)
  const leadSourceRestricted = ref(false)
  const wywLinks = ref([{
      title: 'Explore Lendio business resources',
      url: '/bp/portal/business-resources',
      icon: 'icon-list'
    },
    {
      title: 'Learn more ways to use a line of credit during the pandemic',
      url: 'https://www.lendio.com/blog/line-credit-during-pandemic/',
      icon: 'icon-file'
    },
    {
      title: 'Still have questions? FAQs',
      url: 'https://answers.lendio.com/home',
      icon: 'icon-question'
    }
  ])

  const currentDraw = ref('First Draw')
  const readyCapitalAppNumber = ref(null)
  const scorecardValues = ref(null)
  const partners = ref(PartnersLib.partners)
  const portalLoaded = ref(false)
  const actionItems = ref({})
  const actionItemsTest = ref(false)
  const dnqCta = ref(true)

/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/
  const showDNQCta = computed(() => {
    const isDNQ = experienceStore.isDNQ
    const mineralGroup = get(
      borrowerStore,
      'borrower.mineralGroup'
    )

    if (
      !dnqCta.value ||
      !userStore.hasOptimusUser ||
      !isDNQ ||
      mineralGroup !== 'Coal'
    )
      return false
    const sixMonthsAgo = new Date() - 180 * 24 * 60 * 60 * 1000
    const applications = progressStore.sortedApplications
      .filter((app) =>
        ['marketplace', 'renewal', 'dnqReturn'].includes(app.type)
      )
      .concat([
        {
          started: get(
            borrowerStore,
            'borrowerValues.marketplaceAppStarted.value',
            0
          ),
          completed: get(
            borrowerStore,
            'borrowerValues.marketplaceAppCompleted.value',
            0
          )
        }
      ])
      .map((app) => ({
        started: new Date(app.started || 0),
        completed: new Date(app.completed || 0)
      }))
    const currentDnqApp = progressStore.sortedApplications.find((app) => app.type == 'dnqReturn')
    const recent = applications.reduce(
      (recent, app) => ({
        started: recent.started > app.started ? recent.started : app.started,
        completed:
          recent.completed > app.completed ? recent.completed : app.completed
      }),
      { started: new Date(0), completed: new Date(0) }
    )

    // If they've completed in the last six months, it should count
    if (recent.completed > sixMonthsAgo) return false

    const overSixMonthsOld = recent.started < sixMonthsAgo
    const afterDefaultDate = recent.started > new Date(0)
    const prevDnqNotComplete = currentDnqApp && !currentDnqApp.completed
    const prevDnqOverSixMonthsOld =
      currentDnqApp && currentDnqApp.completed < sixMonthsAgo

    return (
      (overSixMonthsOld && afterDefaultDate) ||
      prevDnqNotComplete ||
      prevDnqOverSixMonthsOld
    )
  })

  const partnerByName = computed(() => {
    return (name) => {
      return partners.value.find((_partners) => _partners.name === name)
    }
  })

  const isQualifiedBorrower = computed(() => {
    const QUALIFIED_CREDIT_THRESHOLD = 550
    const QUALIFIED_REVENUE_THRESHOLD = 8000

    const borrower = get(borrowerStore, 'borrower')
    const borrowerValues = get(
      borrowerStore,
      'borrowerValues'
    )
    if (borrower === null || borrowerValues === null) {
      return null
    }

    const isJumpball = experienceStore.isJumpball
    const isAutomation = experienceStore.isAutomation
    if (isJumpball || isAutomation) {
      return true
    }

    // check if borrower is disqualified by credit score
    const creditScore = parseInt(get(borrowerValues, 'creditScore.value'))
    if (creditScore < QUALIFIED_CREDIT_THRESHOLD) {
      return false
    }

    // check if borrower is disqualified for revenue being too low
    const averageMonthlySales = parseInt(
      get(borrowerValues, 'average_monthly_sales.value')
    )
    const annualRevenue = parseInt(get(borrowerValues, 'annualRevenue.value'))
    const averageMonthlyRevenue = annualRevenue ? annualRevenue / 12 : 0
    // if both values are below revenue threshold, borrower is unqualified
    if (
      averageMonthlySales < QUALIFIED_REVENUE_THRESHOLD &&
      averageMonthlyRevenue < QUALIFIED_REVENUE_THRESHOLD
    ) {
      return false
    }
    return true
  })

  const getModalContent = computed(() => {
    return (searchParam) => {
      const borrowerValues = get(
        borrowerStore,
        'borrowerValues'
      )
      const hasOpted = get(borrowerValues, 'marketingSmsOptIn.value')
      /// if they have NOT been asked previously
      if (hasOpted === undefined || (hasOpted !== '0' && hasOpted !== '1')) {
        return modalContent['smsOptIn']
      }
      if (process.client && window) {
        const param = localStorageService.getItem('portalModal')
        if (param === searchParam) {
          if (param === 'appComplete') {
            return isQualifiedBorrower.value
              ? modalContent['offers']
              : modalContent['dnq']
          }
          return modalContent[param]
        }
      }
      return false
    }
  })

  const creditChallenged = computed(() => {
    const borrowerValues = get(borrowerStore, 'borrowerValues')
    const creditScore = get(borrowerValues, 'creditScore.value')
    if (!creditScore || creditScore < 600) {
      return true
    }
    return false
  })

  const getWywLinks = computed(() => {
    const navUrls = Object.values(PORTAL_NAV_URLS)
    let wywCopy = [...wywLinks.value]
    return wywCopy.filter((link) => {
      const isNav = navUrls.find(
        (navUrl) => link.url.replace('/bp/', '/') === navUrl
      )
      const isCurrentNavLink = navLinks.value.find((nav) => nav.url === isNav)
      return !isNav || isCurrentNavLink
    })
  })

  const portalInitialStep = computed(() => {
    const applicationComplete = borrowerStore.mpApplicationComplete
    const appCompleteInLast30Days =
      checkAppCompleteInLast30Days(applicationComplete)
    if (!appCompleteInLast30Days) {
      return PORTAL_APP_STATE.INCOMPLETE
    }

    const dashHideLenderDocsReq = env('dashHideLenderDocsReq') == 'true'
    const hasBankDocs = documentsStore.hasBankDocs
    const hasLenderDocs = documentsStore.additionalDocsSubmitted
    if ((dashHideLenderDocsReq || hasLenderDocs) && hasBankDocs) {
      return PORTAL_APP_STATE.COMPLETE
    }
    if (hasBankDocs) {
      return PORTAL_APP_STATE.LENDER_DOCS
    }
    return PORTAL_APP_STATE.BANK_DOCS
  })

  const getDaysSinceAppComplete = computed(() => {
    const applicationComplete = borrowerStore.mpApplicationComplete
    return checkDaysSinceAppComplete(applicationComplete)
  })

  const finalizedScorecards = computed(() => {
    if (!scorecardValues.value) {
      return scorecardValues.value
    }

    const finalScorecards = [...scorecardValues.value].sort((a, b) => a.order - b.order)
    return finalScorecards
  })

  const hideExploreTab = computed(() => {
    const isCoreOrPrime = get(borrowerStore, 'isCoreOrPrime')
    const hasFundedDeals = get(dealsStore, 'hasFundedTierOneDeals')
    const tierThreeOptionAvailable = get(dealsStore, 'normalizedEligibleOffers.length') > 0

    return isCoreOrPrime && !hasFundedDeals && !tierThreeOptionAvailable
  })

  const useModernLayout = computed(() => {
    return Boolean(affiliateCustomizationStore.modernPortalLayout || affiliateCustomizationStore.isLendioTenant)
  })

  const getNavLinks = computed(() => {
    let navLinkCopy = [...navLinks.value]
    // Filter unavailable routes
    if (creditChallenged.value) {
      navLinkCopy = navLinkCopy.filter(
        (nav) => nav.title !== PORTAL_NAV_TITLES.CREDIT_CARDS
      )
    }
    const marketplaceAppCompleted = get(borrowerStore, 'borrowerValues.marketplaceAppCompleted.value')
    if (!isQualifiedBorrower.value && !marketplaceAppCompleted) {
      navLinkCopy = navLinkCopy.filter(
        (nav) => nav.title !== PORTAL_NAV_TITLES.OFFERS
      )
    }

    if (hideExploreTab.value) {
      navLinkCopy = navLinkCopy.filter(
        (nav) => nav.title !== PORTAL_NAV_TITLES.EXPLORE
      )
    }

    const borrower = get(borrowerStore, 'borrower')
    if (!borrower) {
      navLinkCopy = navLinkCopy.filter(
        (nav) => nav.title !== PORTAL_NAV_TITLES.SUNRISE
      )
    }
    return navLinkCopy
  })
  const getEmbeddedNavLinks = computed(() => {
    let embeddedNavLinksCopy = [...embeddedNavLinks.value]
    const dealWithAcceptedOffer = get(
      dealsStore,
      'dealWithAcceptedOffer'
    )
    return embeddedNavLinksCopy;
  })

  const getIntelligentLendingNavLinks = computed(() => {
    let intelligentLendingNavLinksCopy = [...intelligentLendingNavLinks.value]
    const dealWithAcceptedOffer = get(
      dealsStore,
      'dealWithAcceptedOffer'
    )
    return intelligentLendingNavLinksCopy.filter((link) => (
      link.title !== INTELLIGENT_LENDING_PORTAL_NAV_TITLES.DASHBOARD
    ))
  })

  const navLinksForCurrentApp = computed(() => {
    switch (progressStore.currentApp.type) {
      case 'embedded':
        return getEmbeddedNavLinks.value
      case 'sba-complete':
        return SBA_COMPLETE_NAV_LINKS
      case 'marketplace':
        return affiliateCustomizationStore.affiliateCustomizations?.affiliate?.medium === 'Intelligent Lending' ? getIntelligentLendingNavLinks.value : getNavLinks.value
      default:
        return getNavLinks.value
    }
  })

  // Only return action count for borrowers in self-serve flow.
  const getActionsNeeded = computed(() => {
    const SELF_SERVE_REP_ID = get(
      ownershipStore,
      'selfServeUserId'
    )
    const SELF_SERVE_RENEWAL_USER_ID = get(
      ownershipStore,
      'selfServeRenewalUserId'
    )
    const assignedRep = get(borrowerStore, 'userId')
    const isSelfServeBorrower =
      assignedRep === SELF_SERVE_REP_ID ||
      assignedRep === SELF_SERVE_RENEWAL_USER_ID
    const count =
      isSelfServeBorrower &&
      approvalsStore.hasContractRequests
        ? 1
        : isSelfServeBorrower
        ? get(approvalsStore, 'approvals.length')
        : 0

    return {
      [PORTAL_NAV_TITLES.OFFERS]: {
        count: count
      }
    }
  })

  const getMissingActionItemsCount = computed(() => {
    return documentsStore.additionalDocumentsUploaded.reduce((count, requirement) => {
      if (requirement.document && requirement.document.filename && [null, undefined].includes(requirement.progress)) {
        return count
      }
      return count + 1
    }, 0)
  })

/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/
  async function checkActionItemsTest() {
    const mbox = MBOX_DETAILS.ACTION_ITEMS.NAME
    const testValue = await appAnalyticsStore.checkSingleTargetOffer({ mbox, searchTerm: 'actionItems' })
    actionItemsTest.value = testValue
    return testValue
  }

  function setCurrentDraw(_currentDraw) {
    currentDraw.value = _currentDraw
  }

  function setWhileYouWaitLinks(links) {
    wywLinks.value = links
  }

  async function getReadyCapitalAppNumber(dealId) {
    if (readyCapitalAppNumber.value) return readyCapitalAppNumber.value
    try {
      const response = await $axios.get(
        `${env('bpApiUrl')}/ready-capital/${dealId}/application-number`
      )
      const { appNumber } = response.data.data

      readyCapitalAppNumber.value = appNumber
      return appNumber
    } catch (e) {
      console.error(e)
      return null
    }
  }

  async function setupScorecardValues() {
    let bvs = await borrowerStore.getBorrowerValues()
    // Double check borrower values are present, otherwise repull.
    const checkBvs = ScorecardLib.scorecards
      .map((sc) => sc.qualificationAliases)
      .reduce((a, b) => a.concat(b), [])
      .every((alias) => bvs && bvs[alias])
    if (!checkBvs) {
      bvs = await borrowerStore.getBorrowerValues({
        forceReload: true
      })
    }
    const _scorecardValues = ScorecardLib.scorecards.map((scorecard) => {
      const alias = scorecard.bvAlias
      const payload = { alias }
      for (const key of scorecard.qualificationAliases) {
        payload[key] = bvs && bvs[key] ? bvs[key].value : null
        if (key === 'timeInBusiness') {
          payload['monthsInBusiness'] = borrowerStore.getMonthsInBusiness
        }
      }
      const scoreByAlias = ScorecardLib.scoreValueFunctions[alias]
      const computedValues = scoreByAlias(payload)
      return Object.assign(scorecard, computedValues)
    })
    _scorecardValues.sort((a, b) => a.order - b.order)
    scorecardValues.value = _scorecardValues
    return _scorecardValues
  }

  return {
    // STATE
    navLinks,
    embeddedNavLinks,
    leadSourceRestricted,
    wywLinks,
    currentDraw,
    readyCapitalAppNumber,
    scorecardValues,
    partners,
    portalLoaded,
    actionItems,
    actionItemsTest,
    dnqCta,

    // GETTERS
    showDNQCta,
    getModalContent,
    partnerByName,
    isQualifiedBorrower,
    creditChallenged,
    getWywLinks,
    portalInitialStep,
    getDaysSinceAppComplete,
    navLinksForCurrentApp,
    finalizedScorecards,
    hideExploreTab,
    getNavLinks,
    getEmbeddedNavLinks,
    getIntelligentLendingNavLinks,
    getActionsNeeded,
    getMissingActionItemsCount,
    useModernLayout,

    // ACTIONS
    checkActionItemsTest,
    setCurrentDraw,
    setWhileYouWaitLinks,
    getReadyCapitalAppNumber,
    setupScorecardValues,
  }
})
